/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BalanceType {
  AVAILABLE = "AVAILABLE",
  CASH = "CASH",
  CASH_AVAILABLE = "CASH_AVAILABLE",
  CASH_TOTAL = "CASH_TOTAL",
  MARGIN = "MARGIN",
  MARGIN_ACCOUNT_BUYING_POWER = "MARGIN_ACCOUNT_BUYING_POWER",
  POSITIONS_TOTAL = "POSITIONS_TOTAL",
  SHORT = "SHORT",
  TOTAL_ACCOUNT = "TOTAL_ACCOUNT",
  TOTAL_TRADE_DATE = "TOTAL_TRADE_DATE",
  TYPE_5 = "TYPE_5",
  UNKNOWN_BALANCE = "UNKNOWN_BALANCE",
  WITHDRAWAL_LIMIT = "WITHDRAWAL_LIMIT",
}

export enum ConsumerRoleType {
  CLIENT = "CLIENT",
  FINANCIAL_ADVISOR = "FINANCIAL_ADVISOR",
  INVESTMENT_SPECIALIST = "INVESTMENT_SPECIALIST",
}

export enum ContactType {
  DAYTIME_PHONE = "DAYTIME_PHONE",
  DIGITAL = "DIGITAL",
  EMAIL = "EMAIL",
  GENERIC_PHONE = "GENERIC_PHONE",
  INTERNATIONAL_PHONE = "INTERNATIONAL_PHONE",
  LANDLINE = "LANDLINE",
  MOBILE = "MOBILE",
  TOLL_FREE = "TOLL_FREE",
  UNKNOWN = "UNKNOWN",
}

/**
 * A list of valid types for creating an account
 */
export enum CreationType {
  FULL_ACCOUNT_TRANSFER = "FULL_ACCOUNT_TRANSFER",
  NEW_ACCOUNT = "NEW_ACCOUNT",
  PARTIAL_ACCOUNT_TRANSFER = "PARTIAL_ACCOUNT_TRANSFER",
}

export enum FinancialAccountStatus {
  ABANDONED = "ABANDONED",
  ACTIVE = "ACTIVE",
  ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED = "ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED",
  CLOSED = "CLOSED",
  LEGAL_DOCUMENTS_PREPARED = "LEGAL_DOCUMENTS_PREPARED",
  LEGAL_DOCUMENTS_SIGNATURE_FAILED = "LEGAL_DOCUMENTS_SIGNATURE_FAILED",
  LEGAL_DOCUMENTS_SIGNED = "LEGAL_DOCUMENTS_SIGNED",
  LEGAL_DOCUMENTS_SUBMITTED = "LEGAL_DOCUMENTS_SUBMITTED",
  NEW = "NEW",
  NOT_STARTED = "NOT_STARTED",
  PARTIAL = "PARTIAL",
  PENDING_CLOSED = "PENDING_CLOSED",
  UNKNOWN_FINANCIAL_ACCOUNT_STATUS = "UNKNOWN_FINANCIAL_ACCOUNT_STATUS",
}

export enum FinancialAccountType {
  ANNUITY = "ANNUITY",
  BENEFICIARY_ROTH_IRA_MINOR_CUSTODIAN = "BENEFICIARY_ROTH_IRA_MINOR_CUSTODIAN",
  BENEFICIARY_ROTH_IRA_TRUST = "BENEFICIARY_ROTH_IRA_TRUST",
  BENEFICIARY_TRADITIONAL_IRA_MINOR_CUSTODIAN = "BENEFICIARY_TRADITIONAL_IRA_MINOR_CUSTODIAN",
  BENEFICIARY_TRADITIONAL_IRA_TRUST = "BENEFICIARY_TRADITIONAL_IRA_TRUST",
  BROKERAGE = "BROKERAGE",
  BROKERAGE_CASH = "BROKERAGE_CASH",
  BROKERAGE_MARGIN = "BROKERAGE_MARGIN",
  BROKERAGE_OPTION = "BROKERAGE_OPTION",
  BUSINESS = "BUSINESS",
  CHEQUING = "CHEQUING",
  CODA_SEP = "CODA_SEP",
  CONVERSION = "CONVERSION",
  CORPORATION = "CORPORATION",
  COVERDELL_EDUCATION_SAVINGS_PLAN = "COVERDELL_EDUCATION_SAVINGS_PLAN",
  CUSTODIAL = "CUSTODIAL",
  CUSTODIAL_ROLLOVER_IRA = "CUSTODIAL_ROLLOVER_IRA",
  CUSTODIAL_ROTH_IRA = "CUSTODIAL_ROTH_IRA",
  CUSTODIAL_TRADITIONAL_IRA = "CUSTODIAL_TRADITIONAL_IRA",
  DEFINED_BENEFIT_PENSION = "DEFINED_BENEFIT_PENSION",
  EMPLOYEE_STOCK_PURCHASE_PLAN = "EMPLOYEE_STOCK_PURCHASE_PLAN",
  FHS = "FHS",
  FIVE_TWO_NINE = "FIVE_TWO_NINE",
  FOUR_FIVE_SEVEN_B = "FOUR_FIVE_SEVEN_B",
  FOUR_ZERO_ONE_K = "FOUR_ZERO_ONE_K",
  FOUR_ZERO_THREE_B = "FOUR_ZERO_THREE_B",
  INDIVIDUAL = "INDIVIDUAL",
  INHERITED_IRA = "INHERITED_IRA",
  INHERITED_ROTH_IRA = "INHERITED_ROTH_IRA",
  INHERITED_TRADITIONAL_IRA = "INHERITED_TRADITIONAL_IRA",
  INVESTMENT = "INVESTMENT",
  JOINT = "JOINT",
  JOINT_TENANTS_BY_ENTIRETY = "JOINT_TENANTS_BY_ENTIRETY",
  JOINT_TENANTS_IN_COMMON = "JOINT_TENANTS_IN_COMMON",
  KEOGH = "KEOGH",
  LIE = "LIE",
  LIR = "LIR",
  LIS = "LIS",
  LLC = "LLC",
  LRI = "LRI",
  MONEY_MARKET = "MONEY_MARKET",
  NORMAL = "NORMAL",
  NRSPR = "NRSPR",
  OTHER = "OTHER",
  PARTNERSHIP = "PARTNERSHIP",
  PRI = "PRI",
  RDS = "RDS",
  REF = "REF",
  REI = "REI",
  RIS = "RIS",
  RLI = "RLI",
  RMA_DOMESTIC = "RMA_DOMESTIC",
  ROLLOVER_IRA = "ROLLOVER_IRA",
  ROTH_401K = "ROTH_401K",
  ROTH_IRA = "ROTH_IRA",
  RRI = "RRI",
  RRS = "RRS",
  RSL = "RSL",
  RSS = "RSS",
  SAVINGS = "SAVINGS",
  SEP = "SEP",
  SEP_IRA = "SEP_IRA",
  SIMPLE = "SIMPLE",
  SIMPLE_IRA = "SIMPLE_IRA",
  SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
  TFS = "TFS",
  TRADITIONAL_IRA = "TRADITIONAL_IRA",
  TRUST = "TRUST",
  TRUST_UNDER_AGREEMENT = "TRUST_UNDER_AGREEMENT",
  TRUST_UNDER_WILL = "TRUST_UNDER_WILL",
  UGMA = "UGMA",
  UNKNOWN_FINANCIAL_ACCOUNT_TYPE = "UNKNOWN_FINANCIAL_ACCOUNT_TYPE",
  UTMA = "UTMA",
  _401K = "_401K",
}

export enum FlowType {
  ONBOARDING = "ONBOARDING",
  RETAKE = "RETAKE",
}

export enum FundCategoryTypes {
  CASH_EQUIVALENT = "CASH_EQUIVALENT",
  CORPORATE_CLASS = "CORPORATE_CLASS",
  UNKNOWN = "UNKNOWN",
}

export enum GlidePath {
  FLAT = "FLAT",
  RETIREMENT = "RETIREMENT",
}

export enum InterestFrequency {
  LONG_TERM = "LONG_TERM",
  SHORT_TERM = "SHORT_TERM",
}

/**
 * A list of supported languages
 */
export enum LanguageType {
  ENGLISH = "ENGLISH",
  FRENCH = "FRENCH",
}

export enum ManagedProductStatus {
  ABANDONED = "ABANDONED",
  ACTIVE = "ACTIVE",
  ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED = "ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED",
  CLOSED = "CLOSED",
  LEGAL_DOCUMENTS_PREPARED = "LEGAL_DOCUMENTS_PREPARED",
  LEGAL_DOCUMENTS_SIGNATURE_FAILED = "LEGAL_DOCUMENTS_SIGNATURE_FAILED",
  LEGAL_DOCUMENTS_SIGNED = "LEGAL_DOCUMENTS_SIGNED",
  LEGAL_DOCUMENTS_SUBMITTED = "LEGAL_DOCUMENTS_SUBMITTED",
  NEW = "NEW",
  NOT_STARTED = "NOT_STARTED",
  PARTIAL = "PARTIAL",
  PENDING_CLOSED = "PENDING_CLOSED",
  UNKNOWN_FINANCIAL_ACCOUNT_STATUS = "UNKNOWN_FINANCIAL_ACCOUNT_STATUS",
}

export enum ManagedProductType {
  DIGITAL_ADVICE = "DIGITAL_ADVICE",
  DIGITAL_ADVICE_PRO = "DIGITAL_ADVICE_PRO",
}

export enum ModelPortfoliosSortType {
  NON_RETIREMENT = "NON_RETIREMENT",
  RETIREMENT = "RETIREMENT",
}

export enum OnboardingStates {
  ASSET_CONSOLIDATION = "ASSET_CONSOLIDATION",
  FUNDING = "FUNDING",
  GOALS = "GOALS",
  INVESTMENT_SWITCH = "INVESTMENT_SWITCH",
  MADLIBS = "MADLIBS",
  PAPERWORK = "PAPERWORK",
  PLAN = "PLAN",
  PLAYBACK = "PLAYBACK",
  PORTFOLIO_SELECTION = "PORTFOLIO_SELECTION",
  RTQ = "RTQ",
  RTQ_RESULT = "RTQ_RESULT",
  SUITABILITY = "SUITABILITY",
  SUITABILITY_RESULT = "SUITABILITY_RESULT",
  UNKNOWN = "UNKNOWN",
}

export enum PartnerProductEnum {
  GIC = "GIC",
  HISA = "HISA",
  MUTUAL_FUND = "MUTUAL_FUND",
  UNKNOWN = "UNKNOWN",
}

export enum PeriodType {
  FIVE_YEAR = "FIVE_YEAR",
  ONE_YEAR = "ONE_YEAR",
  SINCE_INCEPTION = "SINCE_INCEPTION",
  TEN_YEAR = "TEN_YEAR",
  THREE_YEAR = "THREE_YEAR",
}

export enum PlanUpdateWorkflowStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  DOCS_PREPARED = "DOCS_PREPARED",
  DOCS_SIGNED = "DOCS_SIGNED",
  PENDING = "PENDING",
  SIGNATURE_FAILED = "SIGNATURE_FAILED",
}

export enum QuantityType {
  AVAILABLE = "AVAILABLE",
  LONG = "LONG",
  SHORT = "SHORT",
  TOTAL = "TOTAL",
  UNKNOWN_QUANTITY = "UNKNOWN_QUANTITY",
}

export enum RateUnitType {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum RelationshipName {
  ADVISOR = "ADVISOR",
  AUTHORIZED_INDIVIDUAL = "AUTHORIZED_INDIVIDUAL",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  BENEFICIARY = "BENEFICIARY",
  BENEFICIARY_PRIMARY = "BENEFICIARY_PRIMARY",
  BRANCH_ASSIGNMENT = "BRANCH_ASSIGNMENT",
  COMPENSATION_ADVISOR = "COMPENSATION_ADVISOR",
  CONTROL_PERSON = "CONTROL_PERSON",
  CUSTODIAN = "CUSTODIAN",
  DECEDENT = "DECEDENT",
  DEPENDENT = "DEPENDENT",
  EMPLOYEE = "EMPLOYEE",
  FINANCIAL_ADVISER = "FINANCIAL_ADVISER",
  FINANCIAL_ADVISOR = "FINANCIAL_ADVISOR",
  FINANCIAL_ADVISOR_PRIMARY = "FINANCIAL_ADVISOR_PRIMARY",
  HOUSEHOLD_MEMBER = "HOUSEHOLD_MEMBER",
  OWNER = "OWNER",
  OWNER_JOINT = "OWNER_JOINT",
  OWNER_MINOR = "OWNER_MINOR",
  OWNER_TRUST = "OWNER_TRUST",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  POWER_OF_ATTORNEY_READ_ONLY = "POWER_OF_ATTORNEY_READ_ONLY",
  PRIMARY_ADVISOR = "PRIMARY_ADVISOR",
  PROTECTOR = "PROTECTOR",
  SETTLOR = "SETTLOR",
  TRUST = "TRUST",
  TRUSTED_CONTACT = "TRUSTED_CONTACT",
  TRUSTEE = "TRUSTEE",
  TRUSTEE_AND_SETTLOR = "TRUSTEE_AND_SETTLOR",
  UNKNOWN_RELATIONSHIP_NAME = "UNKNOWN_RELATIONSHIP_NAME",
}

export enum ScheduledTransferStatus {
  ACTIVE = "ACTIVE",
  ACTIVE_AT_BROKERAGE = "ACTIVE_AT_BROKERAGE",
  ATTEMPTING_TO_SUBMIT_TO_BROKERAGE = "ATTEMPTING_TO_SUBMIT_TO_BROKERAGE",
  CANCELLED = "CANCELLED",
  CASH_GENERATED = "CASH_GENERATED",
  COMPLETED = "COMPLETED",
  GENERAL_ERROR = "GENERAL_ERROR",
  INSUFFICIENT_ASSETS = "INSUFFICIENT_ASSETS",
  PENDING = "PENDING",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  SUBMITTED = "SUBMITTED",
  TEST = "TEST",
  TRADES_SUBMITTED = "TRADES_SUBMITTED",
  UNAUTHORIZED = "UNAUTHORIZED",
}

export enum SleeveType {
  CASH = "CASH",
  INVESTMENT = "INVESTMENT",
}

export enum SuspensionType {
  PARTNER = "PARTNER",
  TRADER = "TRADER",
}

export enum TransferFrequency {
  BIMONTHLY = "BIMONTHLY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY",
  ONE_TIME = "ONE_TIME",
  WEEKLY = "WEEKLY",
}

export enum TransferType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
}

/**
 * Fund Input
 */
export interface FundInput {
  id: string;
  term: RateTermInput;
  underlyingId?: string | null;
}

export interface LiveRateInput {
  certificateNumber?: string | null;
  customerInitiated: boolean;
  gicType: string;
  interestBasis: number;
  interestFrequency: InterestFrequency;
  isCompoundRate?: string | null;
  issuer: string;
  maturityDate: SymphonyDateTime;
  pricingRequestId: number;
  rates: LiveRateRatesInput;
  securityId: string;
  strikeDate?: SymphonyDateTime | null;
  term: LiveRateTermsInput;
}

export interface LiveRateRatesInput {
  interimRate?: SymphonyDecimal | null;
  maximumRate?: SymphonyDecimal | null;
  targetRate: SymphonyDecimal;
}

export interface LiveRateTermsInput {
  displayPeriod: number;
  displayUnit: RateUnitType;
  end?: number | null;
  start?: number | null;
  unit?: RateUnitType | null;
}

export interface ModelPortfoliosFilterInput {
  productFilter?: ProductFilterInput | null;
  recommendationFilter?: RecommendationFilterInput | null;
  returnFactorsFilter?: ReturnFactorsFilterInput | null;
  selectedModelPortfolios?: SelectedModelPortfolioFilterInput[] | null;
}

/**
 * How many records to skip and the total records to return
 */
export interface PaginationInput {
  limit: number;
  offset: number;
}

export interface ProductFilterInput {
  brokerageAlias?: string | null;
  isTaxSheltered?: boolean | null;
  recommendedProductType?: PartnerProductEnum[] | null;
  role?: ConsumerRoleType | null;
}

/**
 * Rate Term Input
 */
export interface RateTermInput {
  displayPeriod: number;
  displayUnit: RateUnitType;
  end?: number | null;
  start?: number | null;
  unit?: RateUnitType | null;
}

export interface RecommendationFilterInput {
  eligibleProductVariants?: string[] | null;
  riskScoreMax?: number | null;
  riskScoreMin?: number | null;
  scenarioId?: string | null;
  seriesIds?: number[] | null;
}

export interface ReturnFactorsFilterInput {
  glidePath: GlidePath;
  investmentHorizon?: number | null;
}

/**
 * The subset of selected model portfolio parameters used to filter model portfolios by.
 */
export interface SelectedModelPortfolioFilterInput {
  modelPortfolioInternalId: number;
  modelPortfolioSeriesId: number;
}

/**
 * The parameters used to select a product using a sleeve
 */
export interface SelectedModelPortfolioInput {
  liveRates?: LiveRateInput[] | null;
  modelPortfolioInternalId: number;
  modelPortfolioSeriesId: number;
  percentage?: SymphonyDecimal | null;
  sleeveType: SleeveType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
