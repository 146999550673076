import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { ComponentProps, useState } from 'react';
import { createSearchParams, Link } from 'react-router-dom';

import { Container, Dropdown, Grid, TextField } from '@sigfig/digital-wealth-core';

import { PageRoute } from '../../App/routes';
import {
  channels,
  connections,
  EntrySearchParamKey,
  intents,
  locales,
  planTypes,
  sources,
  startPoints,
} from '../../contexts/Entry/types';

export const Qa: React.FC = () => {
  const [form, setForm] = useState<Partial<Record<EntrySearchParamKey, string>>>({
    bns_summary_url: 'https://',
    channel: 'PC',
    conn: 'saml',
    intent: 'dashboard',
    locale: 'en',
    session_timeout: '1000',
    source: 'web',
  });
  const searchParams = `?${createSearchParams(form as Record<string, string>).toString()}`;
  const onChange = (key: EntrySearchParamKey): ComponentProps<typeof Dropdown>['onChange'] => v =>
    setForm(f => ({ ...f, [key]: v.target.value }));

  const fields: Record<EntrySearchParamKey, { items?: string[]; required: boolean }> = {
    account_id: { required: false },
    bns_summary_url: { required: true },
    certificate_number: { required: false },
    channel: { items: (channels as unknown) as string[], required: true },
    conn: { items: (connections as unknown) as string[], required: true },
    intent: { items: (intents as unknown) as string[], required: true },
    locale: { items: (locales as unknown) as string[], required: true },
    ipob_app_id: { required: false },
    plan_type: { items: (planTypes as unknown) as string[], required: false },
    security_id: { required: false },
    session_timeout: { required: true },
    source: { items: (sources as unknown) as string[], required: true },
    start_point: { items: (startPoints as unknown) as string[], required: false },
  };

  const missingValues = Object.entries(fields)
    .filter(([key, { required }]) => required && !form[key as EntrySearchParamKey])
    .map(([key]) => key);

  return (
    <Container>
      <Grid container justifyContent="space-between" py={8} spacing={3}>
        <Grid item xs={12}>
          <Box bgcolor="blue" borderRadius={1} my={4} p={1}>
            <Typography color="white" sx={{ wordBreak: 'break-all' }}>
              {searchParams}
            </Typography>
          </Box>
          {!missingValues.length ? (
            <Link
              to={{
                pathname: `/${PageRoute.Redirect}`,
                search: searchParams,
              }}
            >
              Go To Entrypoint
            </Link>
          ) : (
            <Typography>Missing required values: {JSON.stringify(missingValues)}</Typography>
          )}
        </Grid>
        {Object.entries(fields).map(([key, { items, required }]) => (
          <Grid item key={key}>
            {items ? (
              <Dropdown
                items={items.map(v => ({ value: v, label: v }))}
                label={`${key}${required ? '*' : ''}`}
                onChange={onChange(key as EntrySearchParamKey)}
                size="small"
                sx={{ minWidth: 210 }}
                value={form[key as EntrySearchParamKey] ?? ''}
              />
            ) : (
              <TextField
                label={`${key}${required ? '*' : ''}`}
                onChange={onChange(key as EntrySearchParamKey)}
                size="small"
                value={form[key as EntrySearchParamKey] ?? ''}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
