import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Container } from '@sigfig/digital-wealth-core';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

import { useCurrentPage } from './hooks/useCurrentPage';
import { PageRoute } from './routes';

export const AppLayout: React.FC = () => {
  const currentPage = useCurrentPage();

  if (!currentPage) {
    return <Navigate to={PageRoute.Dashboard} />;
  }

  return (
    <>
      <Header />
      {[PageRoute.Dashboard, PageRoute.Goals, PageRoute.OnboardingStep].includes(currentPage) ? (
        <Outlet />
      ) : (
        <Container component="main">
          <Outlet />
        </Container>
      )}
      <Footer mt={{ xs: 3, md: 6 }} />
    </>
  );
};
