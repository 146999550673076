import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import config from '../config';
import { useEntryContext } from '../contexts/Entry';

export const AuthWrapper: React.FC = ({ children }) => {
  const [entryContext] = useEntryContext();
  const navigate = useNavigate();

  if (!entryContext?.connection) {
    return <>{children}</>;
  }

  if (entryContext.connection === 'saml') {
    return (
      <Auth0Provider
        audience={`https://${config.auth0.audienceEnv}.sigfig.api`}
        clientId={config.auth0.clientId}
        domain={config.auth0.domain}
        onRedirectCallback={appState => {
          navigate((appState && appState.returnTo) || window.location.pathname);
        }}
        redirectUri={window.location.origin}
      >
        {children}
      </Auth0Provider>
    );
  }

  return (
    <Auth0Provider
      audience={`https://${config.auth0.audienceEnv}.sigfig.api`}
      clientId={config.auth0.clientId}
      connection="bns-oidc"
      domain={config.auth0.domain}
      onRedirectCallback={appState => {
        navigate((appState && appState.returnTo) || window.location.pathname);
      }}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};
