import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { ApolloProvider, getApolloClient, getContentstackHttpLinkConfig } from '@sigfig/digital-wealth-core';

import { routes } from '../../App/routes';
import config from '../../config';
import { useEntryContext } from '../../contexts/Entry';
import { SomethingWentWrong } from '../SomethingWentWrong';

export const FourOhFour: React.FC = () => {
  const [entryContext] = useEntryContext();
  const [searchParams] = useSearchParams();

  // ignore auth0 redirect after authentication
  if (searchParams.has('code') && searchParams.has('state')) {
    return null;
  }

  return entryContext ? (
    <Navigate to={routes.dashboard()} />
  ) : (
    <ApolloProvider
      client={getApolloClient({
        links: [getContentstackHttpLinkConfig(config.contentstack.environment, config.contentstack.deliveryToken)],
      })}
    >
      <SomethingWentWrong />
    </ApolloProvider>
  );
};
