import { LinkProps } from '@mui/material';
import React, { ComponentProps } from 'react';

import { Link, RteContent } from '@sigfig/digital-wealth-core';

import { ClickEvent } from '../../hooks/analytics/types';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

import { useBookingUrl } from './hooks/useBookingUrl';

export const BookAppointment: React.FC<ComponentProps<typeof RteContent> & Pick<LinkProps, 'variant'>> = ({
  variant = 'body2',
  data,
  sx,
}) => {
  const bookingUrl = useBookingUrl();
  const { trackClickEvent } = useAnalytics();

  return (
    <RteContent
      anchorComponent={props => (
        <Link
          sx={{ color: 'inherit', textDecorationColor: 'inherit', textUnderlineOffset: '3px' }}
          {...props}
          href={bookingUrl}
          onClick={() => {
            const clickEventData: ClickEvent['click'] = {
              href: null,
              name: 'const:ipob:book an appointment',
              type: 'link',
            };
            trackClickEvent(clickEventData, true);
          }}
          rel="noopener noreferrer"
          role="link"
          target="_blank"
          variant={variant}
        />
      )}
      data={data}
      dataQa="book-an-appointment-message"
      sx={sx}
    />
  );
};
