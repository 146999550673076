import { DataPointType, QuestionnaireEvent } from '@sigfig/digital-wealth-core';

import { ClickEvent } from '../types';

import { questionnaireClickEventDetail, recommendedStrategy } from './configs';

const getClickData = (override: Partial<ClickEvent['click']>): ClickEvent['click'] => ({
  href: null,
  name: '',
  type: 'button',
  ...override,
});

export const getClickEventData = ({
  event,
  eventType,
  question,
  response,
  result,
}: QuestionnaireEvent): ClickEvent['click'] | undefined => {
  const type = eventType === 'radio' ? 'radiobutton' : 'button';
  switch (event) {
    case 'onBack':
      return getClickData({ name: 'const:ipob:back' });
    case 'onNext':
      if (!question || !response) {
        return undefined;
      }
      const clickEvent: ClickEvent['click'] = getClickData({ name: 'const:ipob:continue', type });
      if (question.type === DataPointType.SINGLE_OPTION) {
        const questionDetail = questionnaireClickEventDetail[question.key];
        if (questionDetail) {
          clickEvent.details = questionDetail[response.value];
        } else {
          console.warn('Unknown questionnaire click detail', question);
        }
      }
      return clickEvent;
    case 'onRetake':
      if (result?.riskScoreNew) {
        return getClickData({ name: `const:ipob:retake:${recommendedStrategy[result.riskScoreNew]}` });
      }
      return undefined;
    default:
      return undefined;
  }
};

export const getRtqResultClickActionData = ({
  isRetake,
  resultsCtaConfig,
  riskScoreNew,
}: NonNullable<QuestionnaireEvent['result']>): ClickEvent['click'] | undefined => {
  if (riskScoreNew === undefined || !resultsCtaConfig) {
    return undefined;
  }
  const strategy = recommendedStrategy[riskScoreNew];
  switch (resultsCtaConfig.action) {
    case 'next':
      const name =
        resultsCtaConfig.key === 'save_and_continue'
          ? 'save and continue'
          : resultsCtaConfig.key === 'save_and_review_products'
          ? 'save and review products'
          : 'next';
      return getClickData({ name: `const:ipob:${name}:${strategy}` });
    case 'quit':
      return getClickData({ name: `const:ipob:save and return to dashboard:${strategy}` });
    case 'retake':
      const retakeName = isRetake ? 'retake investor profile' : 'retake';
      return getClickData({ name: `const:ipob:${retakeName}:${strategy}` });
    default:
      return undefined;
  }
};
