import { useCookieValue } from '../../hooks/cookies/useCookieValue';

const optanonGroupKeys = ['C0001', 'C0002', 'C0003', 'C0004', 'C0005'] as const;
type OptanonGroupKey = typeof optanonGroupKeys[number];
const isOptanonGroupKey = (v: any): v is OptanonGroupKey => optanonGroupKeys.includes(v);

interface ConsentValues {
  functional?: boolean;
  necessary?: boolean;
  performance?: boolean;
  socialMedia?: boolean;
  targeting?: boolean;
}

interface ConsentPreferences {
  toggleModal: () => void;
  useValues: () => ConsentValues | undefined;
}

const useValues = (): ConsentValues | undefined => {
  const values = parse(useCookieValue('OptanonConsent'));
  if (!window.Optanon?.IsAlertBoxClosedAndValid()) {
    return undefined;
  }
  return {
    functional: values.C0003,
    necessary: values.C0001,
    performance: values.C0002,
    socialMedia: values.C0005,
    targeting: values.C0004,
  };
};

const parse = (optanonCookie?: string): Partial<Record<OptanonGroupKey, boolean>> => {
  const encodedGroups = optanonCookie
    ?.split('&')
    .find(kvps => kvps.startsWith('groups='))
    ?.split('=')?.[1];
  return encodedGroups
    ? decodeURIComponent(encodedGroups)
        .split(',')
        .reduce<Partial<Record<OptanonGroupKey, boolean>>>((acc, g) => {
          const [key, value] = g.split(':');
          if (isOptanonGroupKey(key) && value) {
            acc[key] = !!+value;
          }
          return acc;
        }, {})
    : {};
};

export const consentPreferences: ConsentPreferences = {
  toggleModal: () => window.Optanon?.ToggleInfoDisplay(),
  useValues,
};
