import { useCallback } from 'react';

import { QuestionnaireEvent } from '@sigfig/digital-wealth-core';

import { useAnalytics } from '../useAnalytics';

import { rtqScreenNames } from './configs';
import { getClickEventData, getRtqResultClickActionData } from './utils';

export const useQuestionnaireEventHandler = (): ((eventData: QuestionnaireEvent) => void) => {
  const { trackClickEvent, trackPageView } = useAnalytics();

  return useCallback(
    (eventData: QuestionnaireEvent) => {
      const { event, question, result } = eventData;
      switch (event) {
        case 'currentQuestion':
          const screenName = question && rtqScreenNames[question.key];
          if (screenName) {
            trackPageView(screenName);
          } else {
            console.warn('Unknown questionnaire event', eventData);
          }
          break;
        case 'result':
          if (!result) {
            console.warn('Invalid questionnaire result', eventData);
            break;
          }
          if (result.action === 'view') {
            trackPageView(
              result.riskScoreCurrent === undefined
                ? 'investmentProfile' // non-retake onboarding flow.
                : result.riskScoreNew === result.riskScoreCurrent
                ? 'retakeRtqInvestmentProfileNoChange'
                : 'retakeRtqInvestmentProfileChange',
            );
            break;
          }
          const rtqResultClickData = getRtqResultClickActionData(result);
          if (rtqResultClickData) {
            trackClickEvent(rtqResultClickData, true);
          } else {
            console.warn('Invalid questionnaire result click event', eventData);
          }
          break;
        case 'onBack':
        case 'onNext':
        case 'onRetake':
          const clickEventData = getClickEventData(eventData);
          if (clickEventData) {
            trackClickEvent(clickEventData, true);
          } else {
            console.warn('Unknown questionnaire click event', eventData);
          }
          break;
      }
    },
    [trackClickEvent, trackPageView],
  );
};
