import { Help as HelpIcon, Home } from '@mui/icons-material';
import React, { ComponentProps, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, KebabMenuItem, useLocale } from '@sigfig/digital-wealth-core';

import { useCurrentPage } from '../../../App/hooks/useCurrentPage';
import { PageRoute, routes } from '../../../App/routes';
import { useApp } from '../../../contexts/App';
import { useEntryContext } from '../../../contexts/Entry';
import { useAccountDashboardClickEvent } from '../../../hooks/analytics/accountDashboard/useAccountDashboardClickEvent';
import { useAuth0 } from '../../../hooks/auth0';
import { useClientInfo } from '../../../hooks/client';
import { EntryApplication } from '../../../hooks/entry/types';
import { parseEntryApplication } from '../../../hooks/entry/util';
import { useGetHeaderContent } from '../contentstack';

import { getAccountDashboardCta, getHeaderContent, getHelpCentreUrl, getPrimaryCtaKeys } from './utils';

interface HeaderContent {
  ctas: {
    key: string;
    label: string;
  }[];
  logo: string;
  logoAltText: string;
  mobileMenuTitle: string;
  subHeader: string;
}

export type HeaderCta = KebabMenuItem & Pick<ComponentProps<typeof Button>, 'color' | 'variant'>;

interface Data {
  clientName?: string;
  content: HeaderContent;
  primaryCtas: HeaderCta[];
  secondaryCta?: HeaderCta;
}
export const ONBOARDING_PAGES = [
  PageRoute.AccountAlreadyInProgress,
  PageRoute.Onboarding,
  PageRoute.OnboardingStep,
  PageRoute.Retake,
  PageRoute.OffRamp,
];

export const useHeaderData = (): Data => {
  const handleAccountDashboardClickEvent = useAccountDashboardClickEvent();
  const [entryContext] = useEntryContext();
  const { contentOptions } = useApp();
  const currentPage = useCurrentPage();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [locale] = useLocale();

  const { bnsSummaryUrl, channel } = entryContext ?? {};
  const entryApplication = parseEntryApplication(channel);
  const partyId = user?.inContextPartyId;

  const { data: contentstackData } = useGetHeaderContent({
    skip: !partyId,
    variables: contentOptions,
  });
  const clientInfo = useClientInfo(ONBOARDING_PAGES.some(p => p === currentPage) ? partyId : '');
  const content = getHeaderContent(contentstackData);
  const primaryCtaKeys = getPrimaryCtaKeys(currentPage);

  const primaryCtas = useMemo(
    () =>
      primaryCtaKeys.reduce<HeaderCta[]>((acc, key) => {
        const text = content.ctas.find(c => c.key === key)?.label;
        if (!text) {
          return acc;
        }

        switch (key) {
          case 'account-dashboard':
            acc.push(getAccountDashboardCta(text, navigate));
            break;
          case 'account-summary':
            acc.push({
              callback: () => {
                handleAccountDashboardClickEvent({ event: 'backToAccountSummary' });
                if (bnsSummaryUrl) {
                  window.location.assign(bnsSummaryUrl);
                }
              },
              color: 'inherit',
              icon: <Home />,
              text,
            });
            break;
          case 'help':
            acc.push({
              callback: () => window.open(getHelpCentreUrl(entryApplication, locale), '_blank'),
              color: 'inherit',
              icon: <HelpIcon fontSize="small" />,
              text,
            });
            break;
          case 'sign-out':
            // For Users coming in from Nova (Channel would be set to N1(iOS) or N2(Android), logout CTA should not be present.)
            if (entryApplication !== EntryApplication.Nova) {
              acc.push({
                callback: () => navigate(routes.logout()),
                text,
                variant: 'outlined',
              });
            }
            break;
        }
        return acc;
      }, []),
    [bnsSummaryUrl, content.ctas, entryApplication, handleAccountDashboardClickEvent, locale, navigate, primaryCtaKeys],
  );

  return useMemo(() => {
    let secondaryCta: HeaderCta | undefined;
    if (currentPage === PageRoute.AccountDetails) {
      const text = content.ctas.find(c => c.key === 'account-dashboard')?.label;
      secondaryCta = text ? getAccountDashboardCta(text, navigate) : undefined;
    }

    return {
      clientName: clientInfo?.name,
      content,
      primaryCtas,
      secondaryCta,
    };
  }, [clientInfo?.name, content, currentPage, navigate, primaryCtas]);
};
