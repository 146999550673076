import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useCurrentPage } from '../../App/hooks/useCurrentPage';
import { PageRoute } from '../../App/routes';

import { ScreenName } from './types';
import { useAnalytics } from './useAnalytics';

const getScreenName = (pathMatch: ReturnType<typeof matchPath>): ScreenName | null => {
  if (!pathMatch) {
    return null;
  }
  const {
    pattern: { path: pageRoute },
    params,
  } = pathMatch;

  switch (pageRoute) {
    case PageRoute.AccountAlreadyInProgress:
      return 'accountAlreadyInProgress';
    case PageRoute.AccountDetails:
      return 'accountDetails';
    case PageRoute.Dashboard:
      return 'dashboard';
    case PageRoute.EligibilityCheck:
      return 'profileChange';
    case PageRoute.GetInTouch:
      return 'getInTouchGeneric';
    case PageRoute.GoalsCreate:
      return 'goalsSetup';
    case PageRoute.GoalsEdit:
      return 'goalsSetupDetails';
    case PageRoute.OffRamp:
      return 'getInTouchAdvisorOfframp';
    case PageRoute.OnboardingStep:
      switch (params.onboardingStep) {
        case 'landing':
          return 'contextPage';
        case 'goals':
          return 'onboardingGoalSelection';
        case 'madlibs':
          return 'madlibs';
        case 'portfolio-selection':
          return 'productSelection';
        default:
          return null;
      }
    case PageRoute.SomethingWentWrong:
      return 'somethingWentWrong';
    default:
      return null;
  }
};

export const useRouteAnalytics = () => {
  const currentPage = useCurrentPage();
  const { pathname } = useLocation();
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    if (!currentPage) {
      return;
    }
    const screenName = getScreenName(matchPath(currentPage, pathname));
    if (screenName) {
      trackPageView(screenName);
    }
  }, [currentPage, pathname, trackPageView]);
};
