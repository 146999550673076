import React, { ComponentProps } from 'react';

import { Box, LoadingButton, Stack, Typography, useIsMediumScreen } from '@sigfig/digital-wealth-core';

export interface SplashPageCta extends ComponentProps<typeof LoadingButton> {
  label: string;
}
export interface Props {
  alignment?: 'left' | 'center' | 'right';
  ctas?: SplashPageCta[];
  dataQa?: string;
  image?: string;
  title?: string;
}

export const SplashPage: React.FC<Props> = ({
  alignment = 'left',
  children,
  ctas,
  dataQa = 'splash-page',
  image,
  title,
}) => {
  const isMobile = useIsMediumScreen();

  return (
    <Stack data-qa={dataQa} justifyContent="center" spacing={2}>
      {image && (
        <Box textAlign={alignment}>
          <img alt="" height={isMobile ? 80 : 118} src={image} width={isMobile ? 80 : 118} />
        </Box>
      )}
      {title && (
        <Typography component="h1" data-qa={`${dataQa}-title`} textAlign={alignment} variant="h3">
          {title}
        </Typography>
      )}
      {children}
      <Stack
        data-qa={`${dataQa}-ctas`}
        direction={isMobile ? 'column-reverse' : 'row'}
        justifyContent={alignment}
        pt={3}
        spacing={2}
      >
        {ctas?.map(({ label, variant, ...buttonProps }, index) => (
          <LoadingButton fullWidth={isMobile} key={index} {...buttonProps} variant={variant ?? 'outlined'}>
            {label}
          </LoadingButton>
        ))}
      </Stack>
    </Stack>
  );
};
