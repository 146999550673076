import { useCallback } from 'react';

import { DataPointType, MadlibsEmitEvent } from '@sigfig/digital-wealth-core';

import { ClickEvent } from '../types';
import { useAnalytics } from '../useAnalytics';

const questionKeyMapping: Record<string, string> = {
  'question:investment-duration': 'investment_term_length',
  'question:net-worth': 'net_worth',
  'question:investment-purpose': 'investment_goal',
  'question:retirement-income-age': 'investment_goal_additional',
};

const getClickData = ({ event, responseData }: MadlibsEmitEvent): ClickEvent['click'] | undefined => {
  const staticData: Pick<ClickEvent['click'], 'href' | 'type' | 'name'> = {
    href: null,
    type: 'button',
    name: 'const:ipob:continue',
  };

  const details = responseData.reduce((acc, cur) => {
    const {
      question: { key, type },
      response: { label, value },
    } = cur;
    const detailsKey = questionKeyMapping[key];
    const detailsValue = type === DataPointType.SINGLE_OPTION ? label : value;
    if (detailsKey && detailsValue) {
      Object.assign(acc, {
        [detailsKey]: detailsValue,
      });
    }
    return acc;
  }, {});

  switch (event) {
    case 'onNext':
      return {
        ...staticData,
        details,
      };
    default:
      return undefined;
  }
};

export const useMadlibsEventHandler = (): ((eventData: MadlibsEmitEvent) => void) => {
  const { trackClickEvent } = useAnalytics();

  return useCallback(
    (eventData: MadlibsEmitEvent) => {
      const clickEventData = getClickData(eventData);
      if (clickEventData) {
        trackClickEvent(clickEventData, true);
      } else {
        console.warn('Unknown Madlibs screen click event', eventData);
      }
    },
    [trackClickEvent],
  );
};
