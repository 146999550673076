import React, { useContext } from 'react';

import { ContentOptions, Locale, ManagedProductType, Partner, Product, useLocale } from '@sigfig/digital-wealth-core';

import config from '../../config';

export interface AppContext {
  brokerageAlias: string;
  contentOptions: ContentOptions;
  program: ManagedProductType;
}

const defaultAppContext: AppContext = {
  brokerageAlias: 'scotiaSecurities',
  contentOptions: {
    environment: config.contentstack.environment,
    locale: Locale.en_us,
    partner: Partner.BNS,
    product: Product.DigitalWealth,
    token: config.contentstack.deliveryToken,
  },
  program: ManagedProductType.DIGITAL_ADVICE,
};

const AppContext = React.createContext(defaultAppContext);

export const AppProvider: React.FC = ({ children }) => {
  const [locale] = useLocale();
  const { contentOptions: defaultContentOptions } = defaultAppContext;

  return (
    <AppContext.Provider
      value={{
        ...defaultAppContext,
        contentOptions: locale ? { ...defaultContentOptions, locale } : defaultContentOptions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = (): AppContext => {
  if (AppContext === undefined) {
    throw new Error('useApp was used without AppProvider');
  }
  return useContext(AppContext);
};
