import { createSearchParams, generatePath } from 'react-router-dom';

import { RetakeContext } from './RetakeWrapper/util';

const generateAbsolutePath: typeof generatePath = (...args) => `/${generatePath(...args)}`;

export type AccountActionSource = 'dashboard' | 'accountDetails';
export type AccountActionParams = {
  certificateNumber?: string;
  managedProductId: string;
  retakeContext: RetakeContext;
  source: AccountActionSource;
};
export enum PageRoute {
  AccountAlreadyInProgress = 'account-already-in-progress/:managedProductId',
  AccountDetails = 'dashboard/:managedProductId',
  AuthedRedirect = 'authed-redirect',
  Dashboard = 'dashboard',
  EligibilityCheck = 'eligibility-check/:source/:retakeContext/:managedProductId',
  EligibilityCheckForEntry = 'eligibility-check',
  GetInTouch = 'get-in-touch',
  Goals = 'goals',
  GoalsCreate = 'goals/create',
  GoalsEdit = 'goals/:goalId',
  Logout = 'logout',
  OffRamp = 'off-ramp',
  Onboarding = 'onboarding',
  OnboardingStep = 'onboarding/:onboardingStep',
  Redirect = 'redirect',
  Retake = 'retake/:retakeContext/:managedProductId',
  ReturnToBns = 'return-to-bns',
  ReviewSelectedProduct = 'review-selected-products/:accountId',
  SomethingWentWrong = 'something-went-wrong',
}

const getSearchParams = (paramValuePair?: Record<string, string | undefined>): string => {
  const paramsWithValue = Object.entries(paramValuePair ?? {})
    .filter(([_, v]) => !!v)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  return Object.keys(paramsWithValue).length ? `?${createSearchParams(paramsWithValue)}` : '';
};

export const routes = {
  accountAlreadyInProgress: (managedProductId: string): string =>
    generateAbsolutePath(PageRoute.AccountAlreadyInProgress, { managedProductId }),
  accountDetails: (managedProductId: string): string =>
    generateAbsolutePath(PageRoute.AccountDetails, { managedProductId }),
  authedRedirect: (): string => generateAbsolutePath(PageRoute.AuthedRedirect),
  dashboard: (): string => generateAbsolutePath(PageRoute.Dashboard),
  eligibilityCheck: (params: AccountActionParams, securityId?: string): string =>
    generateAbsolutePath(PageRoute.EligibilityCheck, params) + getSearchParams({ securityId }),
  eligibilityCheckForEntry: (): string => generateAbsolutePath(PageRoute.EligibilityCheckForEntry),
  getInTouch: (): string => generateAbsolutePath(PageRoute.GetInTouch),
  goals: (): string => generateAbsolutePath(PageRoute.Goals),
  goalsCreate: (goalObjectiveKey?: string): string =>
    generateAbsolutePath(PageRoute.GoalsCreate) + getSearchParams({ goalObjectiveKey }),
  goalsEdit: (goalId: string): string => generateAbsolutePath(PageRoute.GoalsEdit, { goalId }),
  logout: () => generateAbsolutePath(PageRoute.Logout),
  onboarding: (): string => generateAbsolutePath(PageRoute.Onboarding),
  onboardingStep: (onboardingStep: string): string =>
    generateAbsolutePath(PageRoute.OnboardingStep, { onboardingStep }),
  offRamp: (): string => generateAbsolutePath(PageRoute.OffRamp),
  redirect: (): string => generateAbsolutePath(PageRoute.Redirect),
  retake: (
    retakeContext: RetakeContext,
    managedProductId: string,
    searchParams?: { certificateNumber?: string; securityId?: string; source?: AccountActionSource },
  ): string =>
    generateAbsolutePath(PageRoute.Retake, { managedProductId, retakeContext }) + getSearchParams(searchParams),
  returnToBns: () => generateAbsolutePath(PageRoute.ReturnToBns),
  reviewSelectedProduct: (accountId: string): string =>
    generateAbsolutePath(PageRoute.ReviewSelectedProduct, { accountId }),
  somethingWentWrong: (error: any): any =>
    generateAbsolutePath(PageRoute.SomethingWentWrong) + (error ? `?${createSearchParams({ error })}` : ''),
};
