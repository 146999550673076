import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { usePostHog } from '@sigfig/digital-wealth-core';

import { PageRoute } from '../../App/routes';
import config from '../../config';
import { useAuth0 } from '../../hooks/auth0';
import { PageLoading } from '../Loading';

export const Logout: React.FC = () => {
  const { logout: auth0Logout } = useAuth0();
  const [bnsLogoutAttempted, setBnsLogoutAttempted] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    const logoutOfBns = async () => {
      try {
        setBnsLogoutAttempted(true);
        await axios({
          method: 'get',
          url: config.auth0.logout,
          headers: {
            'x-origin-client-id': '6bdb12a8-3ae1-4b8a-94e3-879d308410cf',
          },
        });
      } catch (e: any) {
        console.warn('bns logout attempt failed', e);
      }
    };
    posthog?.reset();
    logoutOfBns();
  }, []);

  useEffect(() => {
    if (bnsLogoutAttempted) {
      auth0Logout({ returnTo: `${window.location.origin}/${PageRoute.ReturnToBns}` });
    }
  }, [auth0Logout, bnsLogoutAttempted]);

  return <PageLoading />;
};
