import { useCallback } from 'react';

import { useCurrentPage } from '../../../App/hooks/useCurrentPage';
import { RetakeContext } from '../../../App/RetakeWrapper/util';
import { PageRoute } from '../../../App/routes';
import { EntryApplicationAccountActionForDeepLink } from '../../../App/util';
import { ClickEvent } from '../types';
import { useAnalytics } from '../useAnalytics';

export type AccountDashboardClickEvent =
  | RetakeContext
  | EntryApplicationAccountActionForDeepLink
  | 'backToAccountSummary'
  | 'manageTcp'
  | 'openNewAccount'
  | 'talkToAdvisor'
  | 'viewPac'
  | 'viewStatements'
  | 'viewTaxDocuments'
  | 'viewTransactionHistory';

export interface AccountDashboardEvent {
  event: AccountDashboardClickEvent;
}

const getClickData = ({ event }: AccountDashboardEvent, isAccountDetails: boolean): ClickEvent['click'] | undefined => {
  const href = null;
  const pageName = isAccountDetails ? 'account details' : 'dashboard';
  switch (event) {
    case 'addFunds':
      return { href, name: `sigfig:${pageName}:add funds`, type: 'link' };
    case 'backToAccountSummary':
      return { href, name: `sigfig:${pageName}:back to account summary`, type: 'link' };
    case 'edit-gic-maturity-instructions':
      return { href, name: `sigfig:${pageName}:edit gic maturity instructions`, type: 'apply' };
    case 'manageTcp':
      return { href, name: `sigfig:${pageName}:manage TCP`, type: 'link' };
    case 'openNewAccount':
      return { href, name: `sigfig:${pageName}:open a new account`, type: 'apply' };
    case 'purchase':
      return { href, name: `sigfig:${pageName}:purchase new investment`, type: 'apply' };
    case 'talkToAdvisor':
      return { href, name: `sigfig:${pageName}:talk to a financial advisor`, type: 'link' };
    case 'retake-questionnaire':
      return { href, name: `sigfig:${pageName}:update investor profile`, type: 'link' };
    case 'viewPac':
      return { href, name: `sigfig:${pageName}:view PAC`, type: 'view more' };
    case 'viewStatements':
      return { href, name: `sigfig:${pageName}:view statements`, type: 'view more' };
    case 'viewTaxDocuments':
      return { href, name: `sigfig:${pageName}:view tax documents`, type: 'view more' };
    case 'viewTransactionHistory':
      return { href, name: `sigfig:${pageName}:view transaction history`, type: 'view more' };
    case 'withdrawFunds':
      return { href, name: `sigfig:${pageName}:withdraw`, type: 'link' };
    default:
      return undefined;
  }
};

export const useAccountDashboardClickEvent = (): ((eventData: AccountDashboardEvent) => void) => {
  const { trackClickEvent } = useAnalytics();
  const currentPage = useCurrentPage();
  const isAccountDetails = currentPage === PageRoute.AccountDetails;

  return useCallback(
    (eventData: AccountDashboardEvent) => {
      const clickEventData = getClickData(eventData, isAccountDetails);
      if (clickEventData) {
        trackClickEvent(clickEventData, false);
      }
    },
    [isAccountDetails, trackClickEvent],
  );
};
