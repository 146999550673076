import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

/**
 * Retrieve the stateful value of a browser cookie.
 * @param {string} key - The key of the browser cookie.
 * @param {{ ms?: number }=} options - Cookie retrieval options.
 * @param {number} [options.ms=500] - The polling interval for checking the value of the cookie. Defaults to 500ms if no value is provided.
 * @return {string | undefined} A stateful value representing the current value of the cookie. This value updates if the cookies value stored by the browser is updated.
 */
export const useCookieValue = (key: string, options?: { ms?: number }): string | undefined => {
  const [value, setValue] = useState<string>();

  useEffect(() => {
    const timer = pollCookieValue({
      key,
      ms: options?.ms ?? 500,
      onChange: setValue,
    });
    return () => {
      clearInterval(timer);
    };
  }, [key, options?.ms]);

  return value;
};

const pollCookieValue = ({ key, ms, onChange }: { key: string; ms: number; onChange: (newValue?: string) => void }) => {
  let previousCookieValue: string | undefined;
  return setInterval(() => {
    const currentCookieValue = Cookies.get(key);
    if (previousCookieValue !== currentCookieValue) {
      onChange(currentCookieValue);
    }
    previousCookieValue = currentCookieValue;
  }, ms);
};
