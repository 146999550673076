import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Alert, getImageAssetUrl, RteContent, Skeleton, Typography } from '@sigfig/digital-wealth-core';

import { SplashPage } from '../../components/SplashPage';
import { useApp } from '../../contexts/App';

import { useSomethingWentWrongContent } from './contentstack';

export interface SomethingWentWrongProps {
  dataQa?: string;
}

export const SomethingWentWrong: React.FC<SomethingWentWrongProps> = ({ dataQa = 'something-went-wrong' }) => {
  const { contentOptions } = useApp();
  const [searchParams] = useSearchParams();
  const missingParamsError = searchParams.get('error');
  const { data, loading, error } = useSomethingWentWrongContent({ variables: contentOptions });

  const content = data?.all_submit_error_modal?.items?.[0];

  return (
    <SplashPage
      data-qa={dataQa}
      image={getImageAssetUrl(content?.modal_info?.modal_iconConnection) ?? ''}
      title={content?.modal_info?.modal_title ?? ''}
    >
      {loading && <Skeleton />}
      {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}

      <RteContent data={content?.modal_info?.modal_content ?? ''} dataQa={`${dataQa}-body`} />

      {missingParamsError && (
        <Typography component="div" data-qa={`${dataQa}-error-message`} sx={{ py: 1, mt: 2 }} variant="body1">
          <RteContent config={{ errorMessageValue: missingParamsError }} data={missingParamsError} />
        </Typography>
      )}
    </SplashPage>
  );
};
