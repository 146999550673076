/* eslint-disable @typescript-eslint/no-empty-function */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SessionTimeoutMessage } from '@sigfig/digital-wealth-core';

import { routes } from '../../App/routes';
import config from '../../config';
import { useApp } from '../../contexts/App';
import { useAuth0 } from '../../hooks/auth0';

interface Props {
  children: ReactNode;
}

export const KeepAlive: React.FC<Props> = ({ children }) => {
  const [isActive, setIsActive] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const { contentOptions } = useApp();
  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  const timeoutMessageRef = useRef<{ resetSession: () => void }>();
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  const callKeepAlive = useCallback(async () => {
    if (user?.jwt && intervalRef.current && isActive) {
      try {
        /**
         * Call to SigFig endpoint.
         */
        await axios({
          method: 'post',
          url: process.env.NODE_ENV === 'development' ? '/auth/v1/keep-alive' : '/symphony/auth/v1/keep-alive',
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        });

        /**
         * Call to Partner endpoint.
         */
        await axios({
          method: 'get',
          url: config.keepAlive.url,
          headers: {
            'x-origin-client-id': '6bdb12a8-3ae1-4b8a-94e3-879d308410cf',
          },
        });

        // On any session refresh, ensure to reset the timeout message to start over again and reset the isAlive flag.
        timeoutMessageRef.current?.resetSession();
        setIsActive(false);
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        setIsActive(false);
      }
    }
  }, [isActive, user?.jwt]);

  useEffect(() => {
    if (!isInitialized) {
      callKeepAlive();
      setIsInitialized(true);
    }
  }, [callKeepAlive, isInitialized]);

  useEffect(() => {
    /**
     * Setting an interval to call keep-alive endpoints every 4 minutes and 45 seconds.
     * Allow for small amount of time before Session has expired message would appear in case user has had no activity in the last 9 minutes and 30 seconds.
     * Timeout message appears every 9 minutes and 45 seconds.
     */

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      callKeepAlive();
    }, 285000);
  }, [callKeepAlive]);

  /**
   * Listen on click, touch, and wheel events. If the user has been active, remove the listener.
   * If the user has not been active, re-attach listener.
   * Wait for 1 second after any event and set isActive to true.
   */
  useEffect(() => {
    const events = ['click', 'touchend', 'touchmove', 'touchstart', 'wheel'];
    const listener = debounce(() => setIsActive(true), 1000);
    if (isActive) {
      events.forEach(event => window.removeEventListener(event, listener));
    } else {
      events.forEach(event => window.addEventListener(event, listener));
    }
  }, [isActive]);

  return (
    <>
      {children}
      <SessionTimeoutMessage
        contentOptions={contentOptions}
        logoutCallback={() => {
          setTimeout(() => {
            logout({ returnTo: window.location.origin });
          }, 1000);
        }}
        navigateCallback={() => navigate(routes.dashboard())}
        ref={timeoutMessageRef}
        refreshCallback={() => setIsActive(true)}
        timeOutInSeconds={585} // Reduce timeout slightly under 10 minutes
        timeoutThreshold={100}
      />
    </>
  );
};
