import { useCallback } from 'react';

import {
  GoalsEvent,
  MadlibsEmitEvent,
  QuestionnaireEvent,
  useAnalyticsEventListener,
  WaysToGetAheadEvent,
} from '@sigfig/digital-wealth-core';

import { useGoalsEventHandler } from './goals/useGoalsEventHandler';
import { useMadlibsEventHandler } from './madlibs/useMadlibsEventHandler';
import { useQuestionnaireEventHandler } from './questionnaire/useQuestionnaireEventHandler';
import { useWaysToGetAheadEventHandler } from './waysToGetAhead/useWaysToGetAheadEventHandler';

type EventData = GoalsEvent | MadlibsEmitEvent | QuestionnaireEvent | WaysToGetAheadEvent;

export const useComponentEventAnalytics = () => {
  const handleGoalsEvent = useGoalsEventHandler();
  const handleMadlibsEvent = useMadlibsEventHandler();
  const handleQuestionnaireEvent = useQuestionnaireEventHandler();
  const handleWaysToGetAheadEvent = useWaysToGetAheadEventHandler();

  useAnalyticsEventListener<EventData>(
    '*',
    useCallback(
      (eventName, eventData) => {
        try {
          switch (eventName) {
            case 'goals':
              handleGoalsEvent(eventData as GoalsEvent);
              return;
            case 'questionnaire':
              handleQuestionnaireEvent(eventData as QuestionnaireEvent);
              return;
            case 'madlibs':
              handleMadlibsEvent(eventData as MadlibsEmitEvent);
              return;
            case 'waysToGetAhead':
              handleWaysToGetAheadEvent(eventData as WaysToGetAheadEvent);
              return;
            default: // do nothing;
          }
        } catch (e) {
          console.warn(`Unable to handle eventName (${eventName.toString()})`, e);
        }
      },
      [handleGoalsEvent, handleMadlibsEvent, handleQuestionnaireEvent, handleWaysToGetAheadEvent],
    ),
  );
};
