import { ScreenName } from '../types';

interface QuestionnaireClickEventDetail {
  [questionKey: string]: { [answerKey: string]: Record<string, string> };
}

export const recommendedStrategy: { [riskScore: number]: string } = {
  1: 'All Cash',
  2: 'Conservative',
  3: 'Moderate',
  4: 'Growth',
  5: 'Aggressive Growth',
};

/**
 * Keyed by the same data-point key shared between the symphony and contentstack.
 */
export const rtqScreenNames: Record<string, ScreenName> = {
  'question:bns-investment-objective': 'rtq1Objective',
  'question:bns-investment-knowledge': 'rtq2Experience',
  'question:bns-savings-rate': 'rtq3PercentageOfNetWorth',
  'question:bns-liquidity': 'rtq4LikelihoodOfLiquidity',
  'question:bns-investing-approach': 'rtq5InvestmentApproach',
  'question:bns-volatility': 'rtq6VolatilityComfort',

  // Madlibs in Retake
  'question:age': 'retakeRtqMadlibsAge',
  'question:investment-duration': 'retakeRtqMadlibsTimeHorizon',
  'question:annual-income': 'retakeRtqMadlibsAnnualHouseHoldIncome',
  'question:net-worth': 'retakeRtqMadlibsNetWorth',
  'question:investment-purpose': 'retakeRtqMadlibsInvestmentGoal',
  'question:retirement-income-age': 'retakeRtqMadlibsInvestmentGoalInvestingForRetirementAge',
  'question:retired-expected-income-age': 'retakeRtqMadlibsInvestmentGoalRetiredAndStillInvestingAge',
  'question:retired-taking-income-since':
    'retakeRtqMadlibsInvestmentGoalRetiredAndHaveBeenTakingIncomeFromMySavingsAge',
};

export const questionnaireClickEventDetail: QuestionnaireClickEventDetail = {
  'question:bns-investment-objective': {
    'data-point-option:bns-investment-objective:capital-preservation': {
      investment_objective: 'Preservation of Capital',
    },
    'data-point-option:bns-investment-objective:income': { investment_objective: 'Income' },
    'data-point-option:bns-investment-objective:income-and-growth': { investment_objective: 'Income and Growth' },
    'data-point-option:bns-investment-objective:growth': { investment_objective: 'Growth' },
  },
  'question:investment-purpose': {
    'data-point-option:investment-purpose:not-for-retirement': { investment_goal: 'Not investing for retirement' },
    'data-point-option:investment-purpose:for-retirement-income': { investment_goal: 'Investing for retirement' },
    'data-point-option:investment-purpose:retired-still-investing': { investment_goal: 'Retired and still investing' },
    'data-point-option:investment-purpose:retired-want-income-now': {
      investment_goal: 'Retired and now want income from my savings',
    },
    'data-point-option:investment-purpose:retired-taking-income': {
      investment_goal: 'Retired and have been taking income from my savings',
    },
  },
  'question:bns-investment-knowledge': {
    'data-point-option:bns-investment-knowledge:expert': { investment_experience: 'Expert' },
    'data-point-option:bns-investment-knowledge:high': { investment_experience: 'High' },
    'data-point-option:bns-investment-knowledge:moderate': { investment_experience: 'Moderate' },
    'data-point-option:bns-investment-knowledge:low': { investment_experience: 'Low' },
    'data-point-option:bns-investment-knowledge:very-low': { investment_experience: 'Very Low' },
  },
  'question:bns-savings-rate': {
    'data-point-option:bns-savings-rate:less-than-20-percent': { investment_percentage: 'Less than 20%' },
    'data-point-option:bns-savings-rate:20-to-40-percent': { investment_percentage: '20%-40%' },
    'data-point-option:bns-savings-rate:41-to-60-percent': { investment_percentage: '41%-60%' },
    'data-point-option:bns-savings-rate:61-to-80-percent': { investment_percentage: '61%-80%' },
    'data-point-option:bns-savings-rate:more-than-80-percent': { investment_percentage: 'More than 80%' },
  },
  'question:bns-liquidity': {
    'data-point-option:bns-liquidity:unlikely': { liquidation_likelihood: 'Unlikely' },
    'data-point-option:bns-liquidity:somewhat-likely': { liquidation_likelihood: 'Somewhat likely' },
    'data-point-option:bns-liquidity:highly-unlikely': { liquidation_likelihood: 'Highly likely' },
  },
  'question:bns-investing-approach': {
    'data-point-option:bns-investing-approach:need-guaranteed-returns': {
      investment_approach: 'Guaranteed return and principle safety',
    },
    'data-point-option:bns-investing-approach:stable-returns': {
      investment_approach: 'Stable returns without fluctuations',
    },
    'data-point-option:bns-investing-approach:mixed-conservative-and-growth': {
      investment_approach: 'Conservative and growth',
    },
    'data-point-option:bns-investing-approach:growth': { investment_approach: 'Growth with some volatility' },
    'data-point-option:bns-investing-approach:aggressive': { investment_approach: 'Growth with high returns' },
  },
  'question:bns-volatility': {
    'data-point-option:bns-volatility:comfortable-30-percent-drop': { volatility_comfort: '30' },
    'data-point-option:bns-volatility:comfortable-20-percent-drop': { volatility_comfort: '20' },
    'data-point-option:bns-volatility:comfortable-15-percent-drop': { volatility_comfort: '15' },
    'data-point-option:bns-volatility:comfortable-10-percent-drop': { volatility_comfort: '10' },
    'data-point-option:bns-volatility:not-comfortable-with-drop': { volatility_comfort: '0' },
  },

  // Madlibs
  'question:investment-duration': {
    'data-point-option:investment-duration:short': { investment_term_length: 'short' },
    'data-point-option:investment-duration:intermediate': { investment_term_length: 'intermediate' },
    'data-point-option:investment-duration:long': { investment_term_length: 'long' },
  },
};
