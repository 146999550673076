import { useEffect, useState } from 'react';

import { useGetClientInfo } from './symphony';
import { ClientInfo } from './types';
import { getClientEmail, getClientName } from './utils';

export const useClientInfo = (partyId?: string): ClientInfo | undefined => {
  const [clientInfo, setClientInfo] = useState<ClientInfo>();
  const { data: clientInfoData, loading: clientInfoLoading, error: clientInfoError } = useGetClientInfo({
    skip: !partyId,
    variables: { partyId: partyId ?? '' },
  });

  useEffect(() => {
    if (clientInfoError) {
      console.error(`Failed to retrieve client info for partyId: [${partyId}], error: ${clientInfoError}`);
    }
  }, [clientInfoError, partyId]);

  useEffect(() => {
    if (!clientInfoLoading && clientInfoData) {
      const party = clientInfoData.client?.party;
      setClientInfo({
        email: getClientEmail(party),
        name: getClientName(party?.partyPerson),
      });
    }
  }, [clientInfoData, clientInfoLoading]);

  return clientInfo;
};
