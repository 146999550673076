import React, { ComponentProps, FC } from 'react';

import { Alert, Box, Container, Grid, Link, RteContent, Skeleton } from '@sigfig/digital-wealth-core';

import { useApp } from '../../contexts/App';
import { consentPreferences } from '../../utils/consentPreferences';

import { useGetFooterContent } from './contentstack';

export const Footer: FC<ComponentProps<typeof Box>> = ({ sx, ...rest }) => {
  const { contentOptions } = useApp();
  const { data, loading, error } = useGetFooterContent({ variables: contentOptions });
  const disclosures = data?.all_bns_app_footer?.items?.[0]?.footer?.disclosures ?? [];

  return (
    <Box component="footer" data-qa="footer" sx={{ backgroundColor: 'secondary.background', py: 6, ...sx }} {...rest}>
      <Container>
        {loading && <Skeleton height={300} />}
        {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}
        <Grid container direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 4 }}>
          {disclosures.map((d, i) => (
            <Grid item key={d?.key} xs="auto">
              {d?.label && d.url && <DisclosureLink href={d.url}>{d.label}</DisclosureLink>}
              {d?.redactor && <RteContent anchorComponent={DisclosureLink} data={d.redactor} />}
              {d?.key === 'cookie' && (
                <DisclosureLink onClick={consentPreferences.toggleModal}>{d.label}</DisclosureLink>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const DisclosureLink = (props: ComponentProps<typeof Link>) => (
  <Link
    sx={{ color: 'inherit', textDecorationColor: 'inherit' }}
    {...props}
    rel="noopener noreferrer"
    target="_blank"
  />
);
