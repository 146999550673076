import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import { GetInTouchContent, GetInTouchContentVariables } from './__generated__/GetInTouchContent';

const query = loader('./query.gql');

export const useGetInTouchContent = (
  options?: QueryHookOptions<GetInTouchContent, GetInTouchContentVariables>,
): QueryResult<GetInTouchContent, GetInTouchContentVariables> => {
  return useContentstackQuery(query, options);
};
