import { useCallback } from 'react';

import { GoalsEvent } from '@sigfig/digital-wealth-core';

import { ClickEvent, ClickType } from '../types';
import { useAnalytics } from '../useAnalytics';

import {
  getConfirmationEventName,
  getCreateEventName,
  getGoalDetailsEventName,
  getOnboardingGoalDetailsName,
  getPageViewScreenName,
  getSummaryEventName,
} from './utils';

type Data = (eventData: GoalsEvent) => void;

export const useGoalsEventHandler = (): Data => {
  const { trackClickEvent, trackPageView } = useAnalytics();

  return useCallback(
    eventData => {
      let details;
      const href = null;
      let isOnboarding = false;
      let name = null;
      let type: ClickType = 'link';
      switch (eventData.componentName) {
        case 'actions':
        case 'associatedAccounts':
        case 'changeTarget':
        case 'editGoalName':
        case 'details':
        case 'graphs':
        case 'header':
          name = getGoalDetailsEventName(eventData);
          break;
        case 'associateAccount':
          const screenName = getPageViewScreenName(eventData);
          if (screenName) {
            trackPageView(screenName);
          }
          break;
        case 'cardActive':
        case 'cardInactive':
        case 'summary':
          name = getSummaryEventName(eventData);
          break;
        case 'completeGoal':
        case 'deleteGoal':
          name = getConfirmationEventName(eventData);
          break;
        case 'createGoal':
          name = getCreateEventName(eventData);
          break;
        case 'onboardingGoalDetails':
          isOnboarding = true;
          if (eventData.name === 'open') {
            trackPageView('onboardingGoalDetails');
          } else {
            name = getOnboardingGoalDetailsName(eventData);
            type = eventData.name === 'skipForLater' ? 'link' : 'button';
          }
          break;
        case 'onboardingGoalSelection':
          isOnboarding = true;
          type = 'button';
          // skipForLater CTA is currently disabled for BNS
          if (eventData.name === 'continue') {
            name = 'const:ipob:continue';
            details = {
              existing_goal: String(eventData.isExistingGoal),
              goal_type: eventData.goalObjective,
              ...(eventData.isExistingGoal ? { existing_goal_title: eventData.goalTitle } : {}),
            };
          }
          break;
      }

      if (!name) {
        return;
      }
      const clickData: ClickEvent['click'] = { href, name, type };
      if (details) {
        clickData.details = details;
      }
      trackClickEvent(clickData, isOnboarding);
    },
    [trackClickEvent, trackPageView],
  );
};
