import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useEntryContext } from '../contexts/Entry';
import { parseEntryContext } from '../contexts/Entry/util';

import { routes } from './routes';
import { removeAuth0Cookies } from './util';

export const Entry: React.FC = () => {
  const [error, setError] = useState<Error>();
  const [initialized, setInitialized] = useState(false);
  const [searchParams] = useSearchParams();
  const [, setEntryContext] = useEntryContext();

  // After OIDC connection is established, there isn't any logout flow between BNS and Sigfig.
  // CTA driven or window close events are not suitable to trigger logout since a new tab/window can be opened,
  // while the existing authed window continues to exist.
  // As a result, the auth0 session with the previous authentication continues unless the auth0 cookies are deleted.
  removeAuth0Cookies();

  useEffect(() => {
    try {
      setEntryContext(parseEntryContext(searchParams));
    } catch (err: any) {
      console.error(`MISSING MANDATORY URL PARAMS ${err}`);
      setError(err);
    } finally {
      setInitialized(true);
    }
  }, [searchParams, setEntryContext]);

  if (!initialized) {
    return null;
  }
  return error ? <Navigate to={routes.somethingWentWrong(error)} /> : <Navigate to={routes.authedRedirect()} />;
};
