// eslint-disable-next-line no-restricted-imports
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';

import { Locale } from '@sigfig/digital-wealth-core';

import { isoLanguageCode, parseLocale } from '../../utils/locale';

interface Auth0User {
  'https://fc.sigfig.com:baseURL': string;
  'https://fc.sigfig.com:frontEndJwt': string;
  'https://fc.sigfig.com:inContextPartyId': string;
  'https://fc.sigfig.com:locale': isoLanguageCode;
}

interface User {
  baseUrl: string;
  inContextPartyId: string;
  jwt: string;
  locale: Locale;
}

interface UseAuth0 extends Pick<Auth0ContextInterface<User>, 'logout' | 'isLoading'> {
  token?: string;
  user?: User;
}

const useAuth0Wrapper = (): UseAuth0 => {
  const { user, isAuthenticated, getAccessTokenSilently, error, logout, isLoading } = useAuth0<Auth0User>();
  const [token, setToken] = useState<string>();

  if (error) {
    throw error;
  }

  if (isAuthenticated && !user?.['https://fc.sigfig.com:frontEndJwt']) {
    throw new Error('User missing JWT');
  }

  useEffect(() => {
    const getAccessToken = async () => {
      if (isAuthenticated) {
        try {
          setToken(await getAccessTokenSilently());
        } catch (e) {
          console.error(e);
        }
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return useMemo(
    () => ({
      logout,
      token,
      isLoading,
      user: user
        ? {
            baseUrl: user['https://fc.sigfig.com:baseURL'],
            inContextPartyId: user['https://fc.sigfig.com:inContextPartyId'],
            jwt: user['https://fc.sigfig.com:frontEndJwt'],
            locale: parseLocale(user['https://fc.sigfig.com:locale']),
          }
        : undefined,
    }),
    [isLoading, logout, token, user],
  );
};

export { useAuth0Wrapper as useAuth0 };
