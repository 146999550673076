import React from 'react';

import { routes } from '../../App/routes';
import { useEntryContext } from '../../contexts/Entry';

export const ReturnToBns: React.FC = () => {
  const [entryContext] = useEntryContext();
  window.location.assign(entryContext?.bnsSummaryUrl ?? routes.dashboard());
  return null;
};
