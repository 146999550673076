import * as React from 'react';
import { createContext, useContext } from 'react';

import { useLocalStorage, useReadLocalStorage } from '@sigfig/digital-wealth-core';

import { EntryContext } from './types';

const entryContext = createContext<[EntryContext | undefined, (ec: EntryContext) => void]>([
  undefined,
  () => undefined,
]);

export const useEntryContext = (): [EntryContext | undefined, (ec: EntryContext) => void] => {
  return useContext(entryContext);
};

export const EntryContextProvider: React.FC<{ value?: EntryContext }> = ({ children, value }) => {
  const storedVal = useReadLocalStorage<EntryContext>('entryContext');
  const [ec, setEc] = useLocalStorage<EntryContext | null>('entryContext', storedVal);

  return <entryContext.Provider value={[value ?? ec ?? undefined, setEc]}>{children}</entryContext.Provider>;
};
