import React from 'react';

import { CircularProgress, Stack } from '@sigfig/digital-wealth-core';

import { useEntryContext } from '../../contexts/Entry';

export const Loading: React.FC = () => <CircularProgress disableShrink />;
export const PageLoading: React.FC = () => {
  const [entryContext] = useEntryContext();

  if (entryContext?.channel === 'N1') {
    // No PageLoading on iPhone, since the iPhone app is expected to have the loading indicator.
    return null;
  }

  return (
    <Stack sx={{ alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
      <CircularProgress disableShrink />
    </Stack>
  );
};
