import { Channel } from '../../contexts/Entry/types';

import { EntryApplication } from './types';

export const parseEntryApplication = (channel?: Channel): EntryApplication | undefined => {
  const applicationMap: Partial<Record<Channel, EntryApplication>> = {
    ME: EntryApplication.Constellation,
    DE: EntryApplication.Constellation,
    S2: EntryApplication.ITrade,
    S1: EntryApplication.ITrade,
    N1: EntryApplication.Nova, // iPhone
    N2: EntryApplication.Nova, // Android
    M1: EntryApplication.Orion,
    PC: EntryApplication.Sol,
  };
  return channel ? applicationMap[channel] : undefined;
};
