import { createSearchParams } from 'react-router-dom';

import { Locale } from '@sigfig/digital-wealth-core';

import config from '../../config';
import { EntryApplication } from '../../hooks/entry/types';

const getReferrerParam = (entryApplication?: EntryApplication): Record<string, string> => {
  switch (entryApplication) {
    case EntryApplication.Nova:
      return { referrer: 'nova' };
    case EntryApplication.Orion:
      return { referrer: 'mobileweb' };
    case EntryApplication.Sol:
      return { referrer: 'sol' };
    default:
      return {};
  }
};

export const getBookingUrl = (entryApplication?: EntryApplication, locale?: Locale): string | undefined => {
  const baseUrl = config.getInTouchBookingUrl;
  const params = createSearchParams({
    ...getReferrerParam(entryApplication),
    language: locale === Locale.fr_ca ? 'fr' : 'en',
    link: 'sigfig',
  });
  return `${baseUrl}?${params.toString()}`;
};
