import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import { GetFooterContent, GetFooterContentVariables } from './__generated__/GetFooterContent';

const query = loader('./query.gql');

export const useGetFooterContent = (
  options?: QueryHookOptions<GetFooterContent, GetFooterContentVariables>,
): QueryResult<GetFooterContent, GetFooterContentVariables> => {
  return useContentstackQuery(query, options);
};
