import config from '../../config';

import { PageView, ProductList } from './types';

/**
 * Adobe Dynamic Tag Management
 */
export const addAdobeDtmScript = () => {
  const script = document.createElement('script');
  script.src = config.analytics.adobeDtmScript;
  script.async = true;

  document.head.appendChild(script);
};

export const getPageViewData = (
  { channel, ...rest }: Partial<PageView['page']>,
  productList?: ProductList[],
): PageView => {
  const isOnboarding = channel === 'sigfig-Discovery & Onboarding';
  return {
    customer: null,
    event: isOnboarding ? 'pageView' : 'Auth.pageView',
    page: {
      applicationName: isOnboarding ? 'sigfig' : '',
      channel: channel ?? 'sigfig-authenticated',
      country: 'CA',
      env: 'lower',
      language: 'en',
      pageName: '',
      pageType: '',
      platformType: 'constellation',
      subSection: 'ipob',
      url: '',
      ...rest,
    },
    productList: productList ?? null,
  };
};
