import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, getImageAssetUrl, Skeleton } from '@sigfig/digital-wealth-core';

import { routes } from '../../App/routes';
import { BookAppointment } from '../../components/BookingAppointment';
import { Props as SplashPageProps, SplashPage } from '../../components/SplashPage';
import { useApp } from '../../contexts/App';
import { ClickEvent } from '../../hooks/analytics/types';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';

import { useGetInTouchContent } from './contentstack';

enum GetInTouchKey {
  FinancialAdvisor = 'financial-advisor',
  OffRamp = 'off-ramp',
}

export interface GetInTouchProps {
  dataQa?: string;
  isOffRamp?: boolean;
}

export const GetInTouch: React.FC<GetInTouchProps> = ({ dataQa = 'get-in-touch', isOffRamp = false }) => {
  const { contentOptions } = useApp();
  const navigate = useNavigate();
  const { data, loading, error } = useGetInTouchContent({ variables: contentOptions });
  const { trackClickEvent } = useAnalytics();

  const content = useMemo(() => {
    const getInTouchContent = data?.all_bns_get_in_touch?.items?.[0];
    const body = getInTouchContent?.body?.find(
      b => b?.key === (isOffRamp ? GetInTouchKey.OffRamp : GetInTouchKey.FinancialAdvisor),
    );

    return {
      body: { key: body?.key ?? '', item: body?.item ?? '' },
      exitApplicationCta: getInTouchContent?.cta?.exit_application ?? '',
      header: getInTouchContent?.header ?? '',
      logo: getImageAssetUrl(getInTouchContent?.imageConnection),
    };
  }, [data?.all_bns_get_in_touch?.items, isOffRamp]);

  const handleExitApplicationClick = () => {
    const clickEventData: ClickEvent['click'] = {
      href: null,
      name: 'const:ipob:exit application',
      type: 'button',
    };
    trackClickEvent(clickEventData, true);
    navigate(routes.dashboard());
  };

  const ctas: SplashPageProps['ctas'] = [
    {
      color: 'secondary',
      label: content.exitApplicationCta,
      onClick: handleExitApplicationClick,
      variant: 'outlined',
    },
  ];

  return (
    <SplashPage ctas={ctas} data-qa={dataQa} image={content.logo} title={content.header}>
      {loading && <Skeleton />}
      {error && <Alert contentOptions={contentOptions} error={error} severity="error" />}
      <BookAppointment data={content.body.item} sx={{ ul: { ml: -1.25 } }} variant="body1" />
    </SplashPage>
  );
};
