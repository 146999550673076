import React, { useEffect } from 'react';

import { usePostHog } from '@sigfig/digital-wealth-core';

import { consentPreferences } from '../../utils/consentPreferences';

export const CookieManager: React.FC = ({ children }) => {
  const postHog = usePostHog();
  const consentPreferenceValues = consentPreferences.useValues();

  useEffect(() => {
    if (consentPreferenceValues?.performance) {
      postHog?.opt_in_capturing();
    } else {
      postHog?.opt_out_capturing();
    }
  }, [consentPreferenceValues?.performance, postHog]);

  return <>{children}</>;
};
