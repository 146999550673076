import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  SomethingWentWrongContent,
  SomethingWentWrongContentVariables,
} from './__generated__/SomethingWentWrongContent';

const query = loader('./query.gql');

export const useSomethingWentWrongContent = (
  options?: QueryHookOptions<SomethingWentWrongContent, SomethingWentWrongContentVariables>,
): QueryResult<SomethingWentWrongContent, SomethingWentWrongContentVariables> => {
  return useContentstackQuery(query, options);
};
