import { useLocale } from '@sigfig/digital-wealth-core';

import { getBookingUrl } from '../../../containers/GetInTouch/util';
import { useEntryContext } from '../../../contexts/Entry';
import { parseEntryApplication } from '../../../hooks/entry/util';

export const useBookingUrl = (): string | undefined => {
  const [locale] = useLocale();
  const [entryContext] = useEntryContext();
  const entryApplication = parseEntryApplication(entryContext?.channel);

  return getBookingUrl(entryApplication, locale);
};
