import { useCallback, useEffect } from 'react';

import { useLocale } from '@sigfig/digital-wealth-core';

import config from '../../config';
import { useEntryContext } from '../../contexts/Entry';

import { pageViewConfigs } from './configs/pageViewConfigs';
import { AnalyticsWindow, ClickEvent, PageView, ProductList, ScreenName } from './types';
import { getPageViewData } from './utils';

declare const window: AnalyticsWindow;

interface AnalyticMethods {
  trackClickEvent: (clickData: ClickEvent['click'], isOnboardingOrRetake: boolean) => void;
  trackPageView: (screenName: ScreenName, productList?: ProductList[]) => void;
}

const pushAppEventData = (data: PageView | ClickEvent): void => {
  if (!window.appEventData) {
    window.appEventData = [];
  }
  window.appEventData.push(data);
};

/**
 * @param baseScreenName if provided, the trackPageView will be invoked automatically with provided ScreenName
 */
export const useAnalytics = (baseScreenName?: ScreenName): AnalyticMethods => {
  const [locale] = useLocale();
  const [entryContext] = useEntryContext();

  const trackClickEvent: AnalyticMethods['trackClickEvent'] = useCallback((click, isOnboarding) => {
    pushAppEventData({
      click,
      event: isOnboarding ? 'click' : 'Auth.click',
    });
  }, []);

  const trackPageView: AnalyticMethods['trackPageView'] = useCallback(
    (screenName, productList) => {
      const pageViewConfig = pageViewConfigs[screenName];
      if (!pageViewConfig) {
        console.warn(`Unknown page view screenName (${screenName})`);
        return;
      }

      const securityId = entryContext?.securityId;
      const { pageName } = pageViewConfig;
      const pageView = getPageViewData(
        {
          ...pageViewConfig,
          env: config.analytics.env,
          language: locale === 'fr-ca' ? 'fr' : 'en',
          pageName: securityId ? `${pageName}:${securityId}` : pageName,
          url: window.location.href,
        },
        productList,
      );
      pushAppEventData(pageView);
    },
    [entryContext?.securityId, locale],
  );

  useEffect(() => {
    if (baseScreenName) {
      trackPageView(baseScreenName);
    }
  }, [baseScreenName, trackPageView]);

  return { trackClickEvent, trackPageView };
};
