import { AccountState } from '@sigfig/digital-wealth-core';

import { ContactType, FinancialAccountStatus } from '../../__generated__/symphonyTypes';

import { GetOwnershipTypeContent_all_ownership_type_items } from './contentstack/__generated__/GetOwnershipTypeContent';
import {
  GetClientInfo_client_party,
  GetClientInfo_client_party_partyPerson,
} from './symphony/__generated__/GetClientInfo';

export const getClientName = (person?: GetClientInfo_client_party_partyPerson | null): string | undefined => {
  if (!person) {
    return;
  }
  return person.displayName || (person.givenName && person.familyName)
    ? `${person.givenName} ${person.familyName}`
    : undefined;
};

export const getClientEmail = (party?: GetClientInfo_client_party | null): string => {
  const primaryEmail = party?.partyContacts?.find(contact => contact.isPrimary && contact.type === ContactType.EMAIL);
  if (primaryEmail) {
    return primaryEmail.contact;
  }
  const email = party?.partyContacts?.find(contact => contact.type === ContactType.EMAIL);
  return email ? email.contact : '';
};

export const isActivatedAccount = (state?: AccountState): boolean =>
  !!state &&
  [
    AccountState.PendingModelPortfolioChange,
    AccountState.RebalanceCompleted,
    AccountState.RebalancePending,
    AccountState.Suspended,
  ].includes(state);

export const getAccountState = ({
  accountNumber,
  financialAccountStatus,
  isRebalanced = false,
  suspendedOn,
}: {
  accountNumber?: string;
  financialAccountStatus: FinancialAccountStatus;
  isRebalanced?: boolean;
  suspendedOn?: string;
}): AccountState => {
  switch (financialAccountStatus) {
    case FinancialAccountStatus.PARTIAL:
      return AccountState.OnboardingIncomplete;
    /**
     * This is a transient state, in most cases an account will move immediately from NEW to LEGAL_DOCUMENTS_PREPARED.
     * For some partners, the account can stay remain in NEW for an extended period of time
     */
    case FinancialAccountStatus.NEW:
      return AccountState.OnboardingWaitingForDocs;
    case FinancialAccountStatus.LEGAL_DOCUMENTS_PREPARED:
      return accountNumber ? AccountState.DocsAndAccountReady : AccountState.DocsReady;
    case FinancialAccountStatus.LEGAL_DOCUMENTS_SIGNED:
      return AccountState.DocsSigned;
    case FinancialAccountStatus.LEGAL_DOCUMENTS_SUBMITTED:
      return AccountState.DocsSubmitted;
    case FinancialAccountStatus.LEGAL_DOCUMENTS_SIGNATURE_FAILED:
    case FinancialAccountStatus.ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED:
      return AccountState.DocsError;
    case FinancialAccountStatus.PENDING_CLOSED:
      return AccountState.PendingClosed;
    case FinancialAccountStatus.ACTIVE:
      if (suspendedOn) {
        return AccountState.Suspended;
      }
      return isRebalanced ? AccountState.RebalanceCompleted : AccountState.RebalancePending;
    default:
      console.warn(`Could not determine account state for financialAccountStatus: ${financialAccountStatus}`);
      return AccountState.Unknown;
  }
};

export const getRelationshipNamesText = (
  relationshipNames: string[],
  content: (GetOwnershipTypeContent_all_ownership_type_items | null)[],
): string => {
  const names = relationshipNames.map(name => content.find(item => item?.key === name)?.text || name);
  if (!names.length) {
    return '';
  } else if (names.length > 1) {
    const jointRelationship = names.find(name => name.toLowerCase().match(/joint/g));
    return jointRelationship ? jointRelationship : names[0];
  } else {
    return names[0];
  }
};
