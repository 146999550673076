import { createSearchParams, useNavigate } from 'react-router-dom';

import { getImageAssetUrl, Locale } from '@sigfig/digital-wealth-core';

import { PageRoute, routes } from '../../../App/routes';
import config from '../../../config';
import { EntryApplication } from '../../../hooks/entry/types';
import { GetHeaderContent } from '../contentstack/__generated__/GetHeaderContent';

import { HeaderCta, useHeaderData } from './useHeaderData';

export const getHeaderContent = (headerContent?: GetHeaderContent): ReturnType<typeof useHeaderData>['content'] => {
  const content = headerContent?.all_header?.items?.[0];
  const logo = getImageAssetUrl(content?.logoConnection);
  const logoAltText = content?.logo_alt_text ?? '';
  const ctas = (content?.ctas ?? []).map(c => ({
    key: c?.key ?? '',
    label: c?.label ?? '',
  }));
  const subHeader = content?.subheader_text ?? '';
  const mobileMenuTitle = content?.mobile_menu_title ?? '';

  return { ctas, logo, logoAltText, mobileMenuTitle, subHeader };
};

type HeaderCtaKeys = 'account-dashboard' | 'account-summary' | 'help' | 'sign-out';
export const getPrimaryCtaKeys = (currentPage?: PageRoute): HeaderCtaKeys[] => {
  switch (currentPage) {
    case PageRoute.SomethingWentWrong:
      return ['help'];
    case PageRoute.AccountDetails:
    case PageRoute.Dashboard:
      return ['account-summary', 'help', 'sign-out'];
    default:
      return ['help', 'account-dashboard', 'sign-out'];
  }
};

export const getAccountDashboardCta = (text: string, navigate: ReturnType<typeof useNavigate>): HeaderCta => ({
  callback: () => navigate(routes.dashboard()),
  text,
  variant: 'outlined',
});

const getOriginParam = (entryApplication?: EntryApplication): Record<string, string> => {
  switch (entryApplication) {
    case EntryApplication.Nova:
      return { origin: 'nova' };
    case EntryApplication.Sol:
      return { origin: 'sol' };
    default:
      return {};
  }
};

export const getHelpCentreUrl = (entryApplication?: EntryApplication, locale?: Locale): string => {
  const baseUrl = config.helpCentreEntryPoint;
  const params = createSearchParams({
    ...getOriginParam(entryApplication),
    referrer: 'sigfig',
  });
  return `${baseUrl}/${locale === Locale.fr_ca ? 'fr' : ''}?${params.toString()}`;
};
