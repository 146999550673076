import * as Sentry from '@sentry/browser';
import React, { lazy, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import {
  ApolloProvider,
  cacheTypePolicies as symphonyCacheTypePolicies,
  getApolloClient,
  getContentstackHttpLinkConfig,
  getGoalsHttpLinkConfig,
  HttpLink,
  InMemoryCache,
  Locale,
  onError,
  setContext,
  useLocale,
  usePostHog,
} from '@sigfig/digital-wealth-core';

import { KeepAlive } from '../components/KeepAlive';
import { PageLoading } from '../components/Loading';
import config from '../config';
import { useEntryContext } from '../contexts/Entry';
import { useComponentEventAnalytics } from '../hooks/analytics/useComponentEventAnalytics';
import { useRouteAnalytics } from '../hooks/analytics/useRouteAnalytics';
import { addAdobeDtmScript } from '../hooks/analytics/utils';
import { useAuth0 } from '../hooks/auth0';

const ProtectedRoute = lazy(() => import('../components/ProtectedRoute'));

export const AuthedApp: React.FC = () => {
  const [entryContext] = useEntryContext();
  useRouteAnalytics();
  useComponentEventAnalytics();
  const { isLoading, user, token } = useAuth0();
  const [locale, setLocale] = useLocale();
  const posthog = usePostHog();

  useEffect(() => {
    if (user?.inContextPartyId) {
      Sentry.setUser({ id: user.inContextPartyId });
      posthog?.identify(user.inContextPartyId);
    }
  }, [posthog, user?.inContextPartyId]);

  useEffect(() => {
    Sentry.setContext('entry context', entryContext ?? null);
  }, [entryContext]);

  useEffect(() => {
    addAdobeDtmScript();
  }, []);

  useEffect(() => {
    const newLocale = entryContext?.locale === 'fr' ? Locale.fr_ca : Locale.en_us;
    if (locale !== newLocale) {
      setLocale(newLocale);
    }
  }, [entryContext?.locale, locale, setLocale]);

  const symphonyAuthLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: user?.jwt ? `Bearer ${user.jwt}` : '',
    },
  }));

  const SymphonyLogLink = onError(({ operation, graphQLErrors, networkError, response }) => {
    Sentry.withScope(scope => {
      const context = operation.getContext();
      scope.setTags({
        'sigfig-correlation-id': context.response?.headers?.get('sigfig-correlation-id'),
        'sigfig-multisession-id': context.response?.headers?.get('sigfig-multisession-id'),
      });
      scope.setContext('operation', {
        ...operation,
      });
      if (graphQLErrors) {
        scope.setContext(
          'graphql Errors',
          graphQLErrors.reduce((acc, e, i) => ({ ...acc, [i]: JSON.stringify(e) }), {}),
        );
      }
      if (networkError) {
        scope.setContext('network error', {
          obj: JSON.stringify(networkError),
        });
      }

      Sentry.captureMessage(`Symphony Error - ${operation.operationName}`, 'error');
    });
  });

  const getSymphonyHttpLinkConfig = (uri: string) => ({
    name: 'symphony',
    link: symphonyAuthLink.concat(SymphonyLogLink).concat(
      new HttpLink({
        uri,
        fetch,
      }),
    ),
  });
  const symphonyUrl = process.env.NODE_ENV === 'development' ? '/ui/graphql' : '/symphony/ui/graphql';
  const goalsUrl = config.goals.url;

  const client = getApolloClient({
    links: [
      getGoalsHttpLinkConfig(goalsUrl, token ?? ''),
      getContentstackHttpLinkConfig(config.contentstack.environment, config.contentstack.deliveryToken),
      getSymphonyHttpLinkConfig(symphonyUrl),
    ],
    cache: new InMemoryCache({
      typePolicies: {
        ...symphonyCacheTypePolicies,
      },
    }),
  });

  return isLoading ? (
    <PageLoading />
  ) : (
    <ProtectedRoute
      component={() => (
        <ApolloProvider client={client}>
          <KeepAlive>
            <Outlet />
          </KeepAlive>
        </ApolloProvider>
      )}
    />
  );
};
