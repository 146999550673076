import { useCallback } from 'react';

import { WaysToGetAheadEvent } from '@sigfig/digital-wealth-core';

import { useAnalytics } from '../useAnalytics';

type Data = (eventData: WaysToGetAheadEvent) => void;

export const useWaysToGetAheadEventHandler = (): Data => {
  const { trackClickEvent } = useAnalytics();

  return useCallback(
    eventData => {
      const href = null;

      switch (eventData.event) {
        case 'click':
          trackClickEvent(
            { href, name: `sigfig:dashboard:wtga:${eventData.title} ${eventData.position}`, type: 'link' },
            false,
          );
          break;
        case 'navigation':
          trackClickEvent({ href, name: 'sigfig:dashboard:wtga:arrow', type: 'generic' }, false);
          break;
      }
    },
    [trackClickEvent],
  );
};
