import { GoalsEvent } from '@sigfig/digital-wealth-core';

import { ScreenName } from '../types';

export const getSummaryEventName = (
  event: Extract<GoalsEvent, { componentName: 'cardActive' | 'cardInactive' | 'summary' }>,
): string | null => {
  const baseName = 'sigfig:dashboard:your goals:';
  switch (event.name) {
    case 'createFirstGoal':
      return `${baseName}create your new goal`;
    case 'createGoal':
      return `${baseName}create new goal`;
    case 'editGoal':
      const source = event.componentName === 'summary' ? 'menu' : 'goal name';
      return `${baseName}${source}:edit goal`;
    case 'deleteCompletedGoal':
      return `${baseName}menu:removed completed goal`;
    case 'deleteGoal':
      return `${baseName}menu:delete goal`;
    case 'finishEditGoal':
      return `${baseName}finish setup`;
    case 'markGoalCompleted':
      return `${baseName}menu:mark as complete`;
    default:
      return null;
  }
};

export const getConfirmationEventName = (
  event: Extract<GoalsEvent, { componentName: 'completeGoal' | 'deleteGoal' }>,
): string | null => {
  const baseName = 'sigfig:dashboard:your goals:confirmation modal:';
  switch (event.name) {
    case 'cancelCompleteGoal':
    case 'cancelDeleteGoal':
      return `${baseName}cancel`;
    case 'confirmCompleteGoal':
      return `${baseName}yes mark as complete`;
    case 'confirmDeleteGoal':
      return `${baseName}yes delete goal`;
    default:
      return null;
  }
};

export const getCreateEventName = (event: Extract<GoalsEvent, { componentName: 'createGoal' }>): string | null => {
  const baseName = 'sigfig:dashboard:goal setup:';
  switch (event.name) {
    case 'cancelCreateGoal':
      return `${baseName}cancel`;
    case 'confirmCreateGoal':
      return `${baseName}${event.title}`;
    default:
      return null;
  }
};

export const getGoalDetailsEventName = (
  event: Extract<
    GoalsEvent,
    {
      componentName:
        | 'actions'
        | 'associatedAccounts'
        | 'changeTarget'
        | 'editGoalName'
        | 'details'
        | 'graphs'
        | 'header';
    }
  >,
): string | null => {
  const baseName = 'sigfig:dashboard:goal setup details:';
  switch (event.name) {
    case 'editGoalName':
      return `${baseName}edit goal name`;
    case 'saveGoalName':
      return `${baseName}save goal name`;
    case 'openNewAccount':
      return `${baseName}open new account`;
    case 'associateExistingAccount':
      return `${baseName}associate existing account`;
    case 'viewSpending':
      return `${baseName}spending view`;
    case 'viewSavings':
      return `${baseName}savings view`;
    case 'editGoal':
      return `${baseName}edit details`;
    case 'saveGoal':
      return `${baseName}save goal details`;
    case 'editAccount':
      return `${baseName}edit account`;
    case 'unassociateAccount':
      return `${baseName}unassociate account`;
    case 'changeTarget':
      return `${baseName}change target amount`;
    case 'saveTarget':
      return `${baseName}save target amount`;
    case 'deleteGoal':
      return `${baseName} delete goal`;
    default:
      return null;
  }
};

export const getOnboardingGoalDetailsName = (
  event: Extract<GoalsEvent, { componentName: 'onboardingGoalDetails' }>,
) => {
  const baseName = 'const:ipob:';
  switch (event.name) {
    case 'cancel':
      return `${baseName}cancel`;
    case 'save':
      return `${baseName}save changes`;
    case 'skipForLater':
      return `${baseName}skip for later`;
  }
};

export const getPageViewScreenName = (
  event: Extract<GoalsEvent, { componentName: 'associateAccount' }>,
): ScreenName | null => {
  switch (event.name) {
    case 'complete':
      return event.accountType === 'existing'
        ? 'goalsAssociateExistingAccountComplete'
        : 'goalsAssociateOtherAccountComplete';
    case 'start':
      return event.accountType === 'existing'
        ? 'goalsAssociateExistingAccountStart'
        : 'goalsAssociateOtherAccountStart';
    default:
      return null;
  }
};
