import { ArrowBackIosNew } from '@mui/icons-material';
import React from 'react';

import {
  Box,
  Button,
  Divider,
  KebabMenu,
  RteContent,
  Stack,
  Typography,
  useIsMediumScreen,
  useTheme,
} from '@sigfig/digital-wealth-core';

import { useHeaderData } from './hooks/useHeaderData';

export interface Props {
  dataQa?: string;
}

export const Header: React.FC<Props> = ({ dataQa = 'header' }) => {
  const isMobile = useIsMediumScreen();
  const { clientName, content, primaryCtas, secondaryCta } = useHeaderData();

  const {
    bnsHeader: { styles },
  } = useTheme();

  return (
    <Stack component="header" data-qa={dataQa}>
      <Stack direction="row" justifyContent="space-between" sx={{ px: { xs: 3, md: 5 }, py: { xs: 1.5, md: 0.75 } }}>
        {content.logo && (
          <img alt={content.logoAltText} data-qa={`${dataQa}-logo`} src={content.logo} width="109.85px" />
        )}
        {isMobile ? (
          <KebabMenu
            buttonProps={{ variant: 'text' }}
            drawerProps={{ title: content.mobileMenuTitle }}
            items={primaryCtas.map(({ callback, icon, text }) => ({
              callback,
              icon,
              text: <Typography variant="subtitle2">{text}</Typography>,
            }))}
          />
        ) : (
          <Stack direction="row" spacing={2}>
            {primaryCtas.map(({ color, callback, icon, text, variant }) => (
              <Box key={text.toString()}>
                <Button color={color} onClick={() => callback()} startIcon={icon} variant={variant}>
                  {text}
                </Button>
              </Box>
            ))}
          </Stack>
        )}
      </Stack>
      <Divider />
      {secondaryCta && (
        <Box data-qa={`${dataQa}-cta`} pl={{ xs: 2 }} pt={1.25}>
          <Button onClick={() => secondaryCta.callback()} size="small" startIcon={<ArrowBackIosNew />}>
            <Typography color="primary" variant="body2">
              {secondaryCta.text}
            </Typography>
          </Button>
        </Box>
      )}
      {clientName && (
        <>
          <Box data-qa={`${dataQa}-client-info`} sx={{ ...styles.subHeader, textAlign: 'center', py: 2 }}>
            {isMobile ? (
              <Typography>{clientName}</Typography>
            ) : (
              <RteContent config={{ clientName }} data={content.subHeader} variantMapping={{ h6: 'div' }} />
            )}
          </Box>
          <Divider />
        </>
      )}
    </Stack>
  );
};
