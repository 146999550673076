import { CoreConfig, defaultCoreConfig } from '@sigfig/digital-wealth-core';

import { Environment as AnalyticsEnvironment } from '../hooks/analytics/types';

import { accountActionUrlConfigs, EntryApplicationAccountAction } from './accountActionUrls';

type Site =
  | 'iqa1'
  | 'iqa2'
  | 'sqa1'
  | 'sqa2'
  | 'sqa3'
  | 'sqa4'
  | 'sqa5'
  | 'sqa6'
  | 'sqa7'
  | 'sqa8'
  | 'sqa9'
  | 'stg'
  | 'prod';
export type ConfigValues<T = string> = Partial<Record<Site, T>> & { _: T };

const isIqa1 = window.location.origin === 'https://dw-iqa1-bns.qa.sigfig.com';
const isIqa2 = window.location.origin === 'https://dw-iqa2-bns.qa.sigfig.com';
const isSqa1 = window.location.origin === 'https://dw-sqa1-bns.qa.sigfig.com';
const isSqa2 = window.location.origin === 'https://dw-sqa2-bns.qa.sigfig.com';
const isSqa3 = window.location.origin === 'https://dw-sqa3-bns.qa.sigfig.com';
const isSqa4 = window.location.origin === 'https://dw-sqa4-bns.qa.sigfig.com';
const isSqa5 = window.location.origin === 'https://dw-sqa5-bns.qa.sigfig.com';
const isSqa6 = window.location.origin === 'https://dw-sqa6-bns.qa.sigfig.com';
const isSqa7 = window.location.origin === 'https://dw-sqa7-bns.qa.sigfig.com';
export const isSqa8 = window.location.origin === 'https://dw-sqa8-bns.qa.sigfig.com';
export const isSqa9 = window.location.origin === 'https://dw-sqa9-bns.qa.sigfig.com';
const isStg = window.location.origin === 'https://dw-stg-bns.qa.sigfig.com';
export const isProd = window.location.origin === 'https://smart-investor-digital.scotiabank.com';
export const isTest = !isStg && !isProd;

const getConfigValue = <T = string>({
  iqa1,
  iqa2,
  sqa1,
  sqa2,
  sqa3,
  sqa4,
  sqa5,
  sqa6,
  sqa7,
  sqa8,
  sqa9,
  stg,
  prod,
  _,
}: ConfigValues<T>): T => {
  if (isIqa1 && iqa1) {
    return iqa1;
  }
  if (isIqa2 && iqa2) {
    return iqa2;
  }
  if (isSqa1 && sqa1) {
    return sqa1;
  }
  if (isSqa2 && sqa2) {
    return sqa2;
  }
  if (isSqa3 && sqa3) {
    return sqa3;
  }
  if (isSqa4 && sqa4) {
    return sqa4;
  }
  if (isSqa5 && sqa5) {
    return sqa5;
  }
  if (isSqa6 && sqa6) {
    return sqa6;
  }
  if (isSqa7 && sqa7) {
    return sqa7;
  }
  if (isSqa8 && sqa8) {
    return sqa8;
  }
  if (isSqa9 && sqa9) {
    return sqa9;
  }
  if (isStg && stg) {
    return stg;
  }
  if (isProd && prod) {
    return prod;
  }
  return _;
};

const getConfigs = (configs: Record<string, ConfigValues>): Record<EntryApplicationAccountAction | string, string> =>
  Object.entries(configs).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = getConfigValue(value);
    return acc;
  }, {});

const apiConfigs = {
  accountActionUrls: getConfigs(accountActionUrlConfigs),
  analytics: {
    adobeDtmScript: getConfigValue({
      prod: 'https://dmtags.scotiabank.com/launch/sigfig/27c34d6e7144/937ca4cd149a/launch-8404754e17f4.min.js',
      _: 'https://assets.adobedtm.com/27c34d6e7144/937ca4cd149a/launch-28e89178fbde-development.min.js',
    }),
    env: getConfigValue<AnalyticsEnvironment>({
      prod: 'production',
      _: 'lower',
    }),
  },
  contentstack: {
    environment: getConfigValue({
      sqa1: 'bns_sqa1_sqa2',
      sqa2: 'bns_sqa1_sqa2',
      sqa3: 'bns_sqa3_sqa4',
      sqa4: 'bns_sqa3_sqa4',
      sqa5: 'bns_sqa5_sqa6',
      sqa6: 'bns_sqa5_sqa6',
      sqa7: 'bns_sqa7_sqa8',
      sqa8: 'bns_sqa7_sqa8',
      sqa9: 'bns_sqa9',
      stg: 'bns_stg',
      prod: 'bns_prod',
      _: 'dev',
    }),
    deliveryToken: getConfigValue({
      sqa1: 'cse012dad342af1d9460fdfae8',
      sqa2: 'cse012dad342af1d9460fdfae8',
      sqa3: 'cs81847d9eb8b7a2a24dc0cda4',
      sqa4: 'cs81847d9eb8b7a2a24dc0cda4',
      sqa5: 'csfb74bfb1dae540935663b17c ',
      sqa6: 'csfb74bfb1dae540935663b17c ',
      sqa7: 'csa3f7ea38c0fc20aca73e44f9',
      sqa8: 'csa3f7ea38c0fc20aca73e44f9',
      sqa9: 'csabaa8c59f4be01ad13f8f173',
      stg: 'cs7be8ff36543972f2ee5744e3',
      prod: 'cs046aec647de153015350df54',
      _: 'csaffbef3139b4b24aee2cc24c',
    }),
  },
  auth0: {
    clientId: getConfigValue({
      iqa2: 'KNZ6iTwr9jf4gQws5YTVMBgFdxKf0ITN',
      sqa1: 'xad3zhtvzocgCmSxry8JxdPQ8ggZPBji',
      sqa2: '64J7jXhhIbB78aEYDEcRDkIE0TOSPACx',
      sqa3: 'Gaa3wjJtpPiPz0CBy5diBG3hGhh6zyk9',
      sqa4: 'HsIbE81MG4r0q6EDyt1j6izteGxWNMHI',
      sqa5: 'hZOaWDLfITlNpn48MiTVpRmsSFV05zt6',
      sqa6: 'LokiObdweowj0WnZjcav2GWHlmFDld2x',
      sqa7: 'koGatPkXjz13HhF3LfJnxF9giKrIWIB6',
      sqa8: 'qK90fJAGJ0dJScdyDsFqZDOsvmKDfuDW',
      sqa9: '6u0A5mY4GeVTLJBTiKBAEVsZYpYPDswh',
      stg: 'tyKzJhNl5eSpUx1lY2p0slIQ9bzzQaYj',
      prod: 'iftVj8dp8NmOAsey0954o7vFcwjS83zi',
      _: 'qUxS5pPe37aTFTO8Ij6dJaKhTPjbqVoO',
    }),
    domain: getConfigValue({
      iqa2: 'bns-iqa2.us.auth0.com',
      sqa1: 'bns-sqa.us.auth0.com',
      sqa2: 'bns-sq2.us.auth0.com',
      sqa3: 'bns-sqa3.us.auth0.com',
      sqa4: 'bns-sqa4.us.auth0.com',
      sqa5: 'bns-sqa5.us.auth0.com',
      sqa6: 'bns-sqa6.us.auth0.com',
      sqa7: 'bns-sqa7.us.auth0.com',
      sqa8: 'bns-sqa8.us.auth0.com',
      sqa9: 'bns-sqa9.us.auth0.com',
      stg: 'bns-stg.us.auth0.com',
      prod: 'bns-prod.us.auth0.com',
      _: 'bns-iqa.us.auth0.com',
    }),
    audienceEnv: getConfigValue({
      iqa1: 'iqa1',
      iqa2: 'iqa2',
      sqa1: 'sqa1',
      sqa2: 'sqa2',
      sqa3: 'sqa3',
      sqa4: 'sqa4',
      sqa5: 'sqa5',
      sqa6: 'sqa6',
      sqa7: 'sqa7',
      sqa8: 'sqa8',
      sqa9: 'sqa9',
      stg: 'stg',
      prod: 'prod',
      _: 'iqa1',
    }),
    logout: getConfigValue({
      iqa1: '',
      iqa2: '',
      sqa1: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/logout',
      sqa2: 'https://auth.scotiaonline.scointnet.net/v2/authentications/sso/logout',
      sqa3: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/logout',
      sqa4: 'https://auth.scotiaonline.scointnet.net/v2/authentications/sso/logout',
      sqa5: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/logout',
      sqa6: 'https://auth.scotiaonline.scointnet.net/v2/authentications/sso/logout',
      sqa7: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/logout',
      sqa8: 'https://auth.scotiaonline.scointnet.net/v2/authentications/sso/logout',
      stg: '',
      prod: 'https://auth.scotiaonline.scotiabank.com/v2/authentications/sso/logout',
      _: 'iqa1',
    }),
  },
  deepLink: {
    key: getConfigValue({
      sqa1:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5MW/+3LQutGcjHhn+pxI\n' +
        'Km4Q6RqdYz0xQxNvXuUbLrnZ1v8HNUTFNbgjnY0Kt2KZnk7V8uVA78osuwT/lS30\n' +
        'gBbvLTpvDRTYWjJM1YcLBQO388TISVJG2jtb8LF5WpGkzIUA2IxQuWRV0fyb4hEe\n' +
        'K/YtcrgZRXkjybJ3hxKxd/CzvyZlY1Sc4eQVMffl+oThVO5gB0DpbkGEHfkgWMtm\n' +
        'aHCv7VDq6Za97CX8Luj+PpySmFl5nI8oNtO4fOJxp2gjlCtJUG8xdQT4Wzaa2rv2\n' +
        'wUy//lIyujvQxw47fL69NtQQ/eVHRhoXPRTZp3y9PLDgHUT6MM0AC/eh6eI2j5fn\n' +
        'RQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa2:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA21ZJcG17lRVK+sypWOxC\n' +
        '/8mq3CNaWXxBVUDE6shsiP333bKpsMmjoz9JcgRB3EnwIkK3nvIZlWq1y+gJ2Vlb\n' +
        'RQRyPc9aMV2PeJFOeRSI2MRvaBY3tYrXCaJsIWZnW3ujyCKv/6MrYzkrjM0Qrl+C\n' +
        'TeNEoXq/CbfFtSNFHihzOckn90zbidSwAF30+UguGXVDnh5NMB8qRDbgHVv1tv0O\n' +
        'c4Yxgjv/QRYibM+8G+rkNS/uxPG/Kd6kGoMLrc6/C/iJ/urNZNZhyGBNWKBuOlmp\n' +
        'kHbBQrNQKRX37+tOKQeGXRR6KYSVcN2+FVc52lrp5ZANTp3rR8Ba5YCgyk1beiYS\n' +
        'xQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa3:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5MW/+3LQutGcjHhn+pxI\n' +
        'Km4Q6RqdYz0xQxNvXuUbLrnZ1v8HNUTFNbgjnY0Kt2KZnk7V8uVA78osuwT/lS30\n' +
        'gBbvLTpvDRTYWjJM1YcLBQO388TISVJG2jtb8LF5WpGkzIUA2IxQuWRV0fyb4hEe\n' +
        'K/YtcrgZRXkjybJ3hxKxd/CzvyZlY1Sc4eQVMffl+oThVO5gB0DpbkGEHfkgWMtm\n' +
        'aHCv7VDq6Za97CX8Luj+PpySmFl5nI8oNtO4fOJxp2gjlCtJUG8xdQT4Wzaa2rv2\n' +
        'wUy//lIyujvQxw47fL69NtQQ/eVHRhoXPRTZp3y9PLDgHUT6MM0AC/eh6eI2j5fn\n' +
        'RQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa4:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA21ZJcG17lRVK+sypWOxC\n' +
        '/8mq3CNaWXxBVUDE6shsiP333bKpsMmjoz9JcgRB3EnwIkK3nvIZlWq1y+gJ2Vlb\n' +
        'RQRyPc9aMV2PeJFOeRSI2MRvaBY3tYrXCaJsIWZnW3ujyCKv/6MrYzkrjM0Qrl+C\n' +
        'TeNEoXq/CbfFtSNFHihzOckn90zbidSwAF30+UguGXVDnh5NMB8qRDbgHVv1tv0O\n' +
        'c4Yxgjv/QRYibM+8G+rkNS/uxPG/Kd6kGoMLrc6/C/iJ/urNZNZhyGBNWKBuOlmp\n' +
        'kHbBQrNQKRX37+tOKQeGXRR6KYSVcN2+FVc52lrp5ZANTp3rR8Ba5YCgyk1beiYS\n' +
        'xQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa5:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5MW/+3LQutGcjHhn+pxI\n' +
        'Km4Q6RqdYz0xQxNvXuUbLrnZ1v8HNUTFNbgjnY0Kt2KZnk7V8uVA78osuwT/lS30\n' +
        'gBbvLTpvDRTYWjJM1YcLBQO388TISVJG2jtb8LF5WpGkzIUA2IxQuWRV0fyb4hEe\n' +
        'K/YtcrgZRXkjybJ3hxKxd/CzvyZlY1Sc4eQVMffl+oThVO5gB0DpbkGEHfkgWMtm\n' +
        'aHCv7VDq6Za97CX8Luj+PpySmFl5nI8oNtO4fOJxp2gjlCtJUG8xdQT4Wzaa2rv2\n' +
        'wUy//lIyujvQxw47fL69NtQQ/eVHRhoXPRTZp3y9PLDgHUT6MM0AC/eh6eI2j5fn\n' +
        'RQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa6:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA21ZJcG17lRVK+sypWOxC\n' +
        '/8mq3CNaWXxBVUDE6shsiP333bKpsMmjoz9JcgRB3EnwIkK3nvIZlWq1y+gJ2Vlb\n' +
        'RQRyPc9aMV2PeJFOeRSI2MRvaBY3tYrXCaJsIWZnW3ujyCKv/6MrYzkrjM0Qrl+C\n' +
        'TeNEoXq/CbfFtSNFHihzOckn90zbidSwAF30+UguGXVDnh5NMB8qRDbgHVv1tv0O\n' +
        'c4Yxgjv/QRYibM+8G+rkNS/uxPG/Kd6kGoMLrc6/C/iJ/urNZNZhyGBNWKBuOlmp\n' +
        'kHbBQrNQKRX37+tOKQeGXRR6KYSVcN2+FVc52lrp5ZANTp3rR8Ba5YCgyk1beiYS\n' +
        'xQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa7:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5MW/+3LQutGcjHhn+pxI\n' +
        'Km4Q6RqdYz0xQxNvXuUbLrnZ1v8HNUTFNbgjnY0Kt2KZnk7V8uVA78osuwT/lS30\n' +
        'gBbvLTpvDRTYWjJM1YcLBQO388TISVJG2jtb8LF5WpGkzIUA2IxQuWRV0fyb4hEe\n' +
        'K/YtcrgZRXkjybJ3hxKxd/CzvyZlY1Sc4eQVMffl+oThVO5gB0DpbkGEHfkgWMtm\n' +
        'aHCv7VDq6Za97CX8Luj+PpySmFl5nI8oNtO4fOJxp2gjlCtJUG8xdQT4Wzaa2rv2\n' +
        'wUy//lIyujvQxw47fL69NtQQ/eVHRhoXPRTZp3y9PLDgHUT6MM0AC/eh6eI2j5fn\n' +
        'RQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa8:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA21ZJcG17lRVK+sypWOxC\n' +
        '/8mq3CNaWXxBVUDE6shsiP333bKpsMmjoz9JcgRB3EnwIkK3nvIZlWq1y+gJ2Vlb\n' +
        'RQRyPc9aMV2PeJFOeRSI2MRvaBY3tYrXCaJsIWZnW3ujyCKv/6MrYzkrjM0Qrl+C\n' +
        'TeNEoXq/CbfFtSNFHihzOckn90zbidSwAF30+UguGXVDnh5NMB8qRDbgHVv1tv0O\n' +
        'c4Yxgjv/QRYibM+8G+rkNS/uxPG/Kd6kGoMLrc6/C/iJ/urNZNZhyGBNWKBuOlmp\n' +
        'kHbBQrNQKRX37+tOKQeGXRR6KYSVcN2+FVc52lrp5ZANTp3rR8Ba5YCgyk1beiYS\n' +
        'xQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa9:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqA62OEC3BrdxE/QZX46j\n' +
        '5kpYQg3BGI4EgOmYOKc8Bqh4KSwp9hzS2rUcifMMrmMtoN6bbeTI+zI6hP6GBylV\n' +
        'kWLF43Y9uTdizhgrcO3Yw2tyXcby77b2IIQBR/P0LXdPyjEOP7Tv/KlWUOys4OIo\n' +
        '34/LjQrdLtWJHxOIca1QkrRpSzZBh8yE0ciqZyqKC2vwSUI+cBafE0cEfHoPdJiz\n' +
        'sDohF/PwEOJrp/ZERiC8dMlVpAa/8N+1DJ6su4Nk0AC1cHC7lSyl3JBxunazdFwj\n' +
        'vkMCvAZQY0Jfem6KzRuZbqaY/piYikd36e59zqFisG4gZO2MzlEMiowCz69dQcb9\n' +
        'LwIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      stg:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqA62OEC3BrdxE/QZX46j\n' +
        '5kpYQg3BGI4EgOmYOKc8Bqh4KSwp9hzS2rUcifMMrmMtoN6bbeTI+zI6hP6GBylV\n' +
        'kWLF43Y9uTdizhgrcO3Yw2tyXcby77b2IIQBR/P0LXdPyjEOP7Tv/KlWUOys4OIo\n' +
        '34/LjQrdLtWJHxOIca1QkrRpSzZBh8yE0ciqZyqKC2vwSUI+cBafE0cEfHoPdJiz\n' +
        'sDohF/PwEOJrp/ZERiC8dMlVpAa/8N+1DJ6su4Nk0AC1cHC7lSyl3JBxunazdFwj\n' +
        'vkMCvAZQY0Jfem6KzRuZbqaY/piYikd36e59zqFisG4gZO2MzlEMiowCz69dQcb9\n' +
        'LwIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      prod:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw/ETLfPzr/SPk1czujuw\n' +
        'djqVPEUjoObIqAY7GmkuZXdXkAVsCC9LJz/UW0t+uJhDOI/Ss2Dux7I7K65vaxHJ\n' +
        'Oi4zveNNt/2uAaCEXQwz8b8y+b+VhdAhOa0GOcUOfJa610869/xzCk2by4l8EqQg\n' +
        'Zm2VUStRt1id2MoELh5sE6jtRl0ijofjATs5CY5xUSi9Mh73+JAmAYKTRNMpe/OX\n' +
        'UNWsTJLhXFwgLPQcwrd7kvUwwkDkrulPhVCMfJjYnm6sUWBqPvifeisk/bTbnxhG\n' +
        '2C8VMoI3Zm7ru81VCJ9N2Fw1Goem3muVgOBVb7ahcJqH+umJ4t8Enr75HXWmEYyV\n' +
        'WwIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      _:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5MW/+3LQutGcjHhn+pxI\n' +
        'Km4Q6RqdYz0xQxNvXuUbLrnZ1v8HNUTFNbgjnY0Kt2KZnk7V8uVA78osuwT/lS30\n' +
        'gBbvLTpvDRTYWjJM1YcLBQO388TISVJG2jtb8LF5WpGkzIUA2IxQuWRV0fyb4hEe\n' +
        'K/YtcrgZRXkjybJ3hxKxd/CzvyZlY1Sc4eQVMffl+oThVO5gB0DpbkGEHfkgWMtm\n' +
        'aHCv7VDq6Za97CX8Luj+PpySmFl5nI8oNtO4fOJxp2gjlCtJUG8xdQT4Wzaa2rv2\n' +
        'wUy//lIyujvQxw47fL69NtQQ/eVHRhoXPRTZp3y9PLDgHUT6MM0AC/eh6eI2j5fn\n' +
        'RQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
    }),
  },
  sentry: {
    dsn: 'https://121b44ecef9d4412a79a76ebb438f2a8@o14749.ingest.sentry.io/6691769',
    environment: getConfigValue({
      iqa1: 'iqa1',
      iqa2: 'iqa2',
      sqa1: 'sqa1',
      sqa2: 'sqa2',
      sqa3: 'sqa3',
      sqa4: 'sqa4',
      sqa5: 'sqa5',
      sqa6: 'sqa6',
      sqa7: 'sqa7',
      sqa8: 'sqa8',
      sqa9: 'sqa9',
      stg: 'staging',
      prod: 'production',
      _: 'dev',
    }),
  },
  newRelic: {
    licenseKey: '3288c5f750',
    applicationId: getConfigValue({
      iqa1: '718275058',
      iqa2: '718275058',
      sqa1: '718289527',
      sqa2: '718289528',
      sqa3: '718289527',
      sqa4: '718289528',
      sqa5: '718289527',
      sqa6: '718289528',
      sqa7: '718289527',
      sqa8: '718289528',
      sqa9: '718276889',
      stg: '718276889',
      prod: '718275043',
      _: '718275058',
    }),
  },
  getInTouchBookingUrl: getConfigValue({
    prod: 'https://hello.scotiabank.com/book',
    _: 'https://hello-u.scointnet.net/book',
  }),
  goals: {
    partnerConfigId: getConfigValue({
      iqa1: '5f073c15-719a-4327-b300-b6eb70f00a7a',
      iqa2: '6dcaa689-c2c3-42a5-9f34-45ff9fb7d475',
      sqa1: '15f9d4ec-d450-4b85-97c2-e416b2aec9d2',
      sqa2: '5c9cd147-afec-4a8f-b86e-c28474abb6bc',
      sqa3: 'a229ee93-06db-49fc-856c-57290614b454',
      sqa4: 'a64e4790-ebc5-4304-8bfa-90f1a5d75ac7',
      sqa5: '5c51205a-7307-477d-bd1b-8ceb195a271f',
      sqa6: '00376e71-5b62-417d-8d9e-4e8e5a6e3ae0',
      sqa7: 'c4e2fc68-4493-4e80-816d-8e12bdd299c9',
      sqa8: '3bc7dc14-62bd-4313-addc-5f5f9754ae33',
      sqa9: 'd8839c15-959f-48b9-92ab-80a7c4d80d1b',
      stg: '79f9e4ea-c43d-408c-ac46-c978a1b7aafa',
      prod: 'dde72493-4c59-4fff-8808-4b76f614b423',
      _: '5f073c15-719a-4327-b300-b6eb70f00a7a',
    }),
    url: getConfigValue({
      iqa1: 'https://47uk4u6fwrbudliyav2m3lyhwa.appsync-api.us-east-2.amazonaws.com/graphql',
      iqa2: 'https://xtizbt263ffe5deogjge47kouu.appsync-api.us-east-1.amazonaws.com/graphql',
      sqa1: 'https://rsfblhbefzambnvg5rjos2m5re.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa2: 'https://336vqqqcdncdpfdnytqafjvvta.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa3: 'https://qexq2rgphbfuzoybgqhbf2jbk4.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa4: 'https://frbjsyr4fjcqxdtjjsbhtjkfg4.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa5: 'https://tkk33xz5nbf7tksuw5nel2z5uu.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa6: 'https://lrrkpqia4nfxzdc5v52hjmij44.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa7: 'https://xi2sodvo3zbvnpemow32m5tecq.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa8: 'https://xfyf2w6365fudl24maqfuo7ac4.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa9: 'https://t5qr644uprfjxawjxxbnjaryse.appsync-api.us-east-2.amazonaws.com/graphql',
      stg: 'https://zexdarsvuzbghdkajju6ziws4e.appsync-api.us-east-2.amazonaws.com/graphql',
      prod: 'https://tzzs37qkjnh5vdfq2o4ynk6may.appsync-api.us-east-2.amazonaws.com/graphql',
      _: 'https://47uk4u6fwrbudliyav2m3lyhwa.appsync-api.us-east-2.amazonaws.com/graphql',
    }),
  },
  helpCentreEntryPoint: getConfigValue({
    iqa1: 'https://dummy-helpCentreEntryPoint',
    iqa2: 'https://dummy-helpCentreEntryPoint',
    sqa1: 'https://nova-search-ui-ist.apps.stg.azr-cc-pcf.cloud.bns',
    sqa2: 'https://cdb-ux-mobile-banking-search-u.scointnet.net',
    sqa3: 'https://nova-search-ui-ist.apps.stg.azr-cc-pcf.cloud.bns',
    sqa4: 'https://cdb-ux-mobile-banking-search-u.scointnet.net',
    sqa5: 'https://nova-search-ui-ist.apps.stg.azr-cc-pcf.cloud.bns',
    sqa6: 'https://cdb-ux-mobile-banking-search-u.scointnet.net',
    sqa7: 'https://nova-search-ui-ist.apps.stg.azr-cc-pcf.cloud.bns',
    sqa8: 'https://cdb-ux-mobile-banking-search-u.scointnet.net',
    stg: 'https://dummy-helpCentreEntryPoint',
    prod: 'https://help.scotiabank.com',
    _: 'https://dummy-helpCentreEntryPoint',
  }),
  keepAlive: {
    url: getConfigValue({
      iqa1: 'https://dummy-keepAlive-url',
      iqa2: 'https://dummy-keepAlive-url',
      sqa1: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/log',
      sqa2: 'https://auth.scotiaonline.scointnet.net/v2/authentications/sso/log',
      sqa3: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/log',
      sqa4: 'https://auth.scotiaonline.scointnet.net/v2/authentications/sso/log',
      sqa5: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/log',
      sqa6: 'https://auth.scotiaonline.scointnet.net/v2/authentications/sso/log',
      sqa7: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/log',
      sqa8: 'https://auth.scotiaonline.scointnet.net/v2/authentications/sso/log',
      sqa9: 'https://phoenix-v2-ist.apps.stg.azr-cc-pcf.cloud.bns/v2/authentication/log',
      stg: 'https://dummy-keepAlive-url',
      prod: 'https://auth.scotiaonline.scotiabank.com/v2/authentications/sso/log',
      _: 'https://dummy-keepAlive-url',
    }),
  },
  posthog: {
    token: getConfigValue({
      prod: 'phc_YwMtXmyJjHJ9AiU0tP7Hs1r9UQwYWKp2rcD5c6W94XF',
      _: 'phc_DfeG81drxq7Akc7hhpneG2qiEebBVhyz2G2ohatVv1u',
    }),
  },
};

export default apiConfigs;

export const coreConfigs: CoreConfig = {
  ...defaultCoreConfig,
  components: {
    ...defaultCoreConfig.components,
    sfGoalSelection: { allowSkipGoalCreationDuringOnboarding: false, isGoalDetailsModalEnabled: true },
    sfMadlibs: { allowDisablingAgeField: true },
    sfQuestionnaire: { allowDisablingAgeField: true },
  },
  featureFlags: {
    ...defaultCoreConfig.featureFlags,
    goals: {
      enableGoalsFeature: true,
      enableBranchIdForGoalCreation: false, // Applicable only to Branch
    },
  },
};
